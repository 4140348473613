const actions = {
  SET_STATE: 'savings/SET_STATE',
  DISPLAY_REGULAR_SAVINGS_ACCOUNT: 'savings/DISPLAY_REGULAR_SAVINGS_ACCOUNT',
  GET_ZERO_SAVINGS_SUMMARY: 'savings/GET_ZERO_SAVINGS_SUMMARY',
  GET_SAVINGS_TRANSACTIONS: 'savings/GET_SAVINGS_TRANSACTIONS',
  GET_SAVINGS_WITHDRAWAL_DETAILS: 'savings/GET_SAVINGS_WITHDRAWAL_DETAILS',
  GET_SAVING_MEMBERS_AUTOCOMPLETE: 'savings/GET_SAVING_MEMBERS_AUTOCOMPLETE',
}

export default actions
