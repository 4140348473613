/* eslint-disable camelcase */
import { notification } from 'antd'
import { all, takeEvery, put, call } from 'redux-saga/effects'
import * as jwt from 'services/jwt'
import actions from './actions'

const jwts = {
  getZeroAccountSummary: jwt.getZeroAccountSummary,
  getZeroPendingAccount: jwt.getZeroPendingAccount,
  getZeroApprovedAccount: jwt.getZeroApprovedAccount,
  getZeroCancelledAccount: jwt.getZeroCancelledAccount,
  getZeroAccountDetails: jwt.getZeroAccountDetails,
  approveDeclineZeroAccount: jwt.approveDeclineZeroAccount,
}

export function* GET_ZERO_ACCOUNT_SUMMARY({ payload }) {
  yield put({
    type: 'account/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getZeroAccountSummary, payload)
  if (success.status) {
    yield put({
      type: 'account/SET_STATE',
      payload: {
        loading: false,
        data: success?.data,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'account/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* GET_ZERO_PENDING_ACCOUNT({ payload }) {
  yield put({
    type: 'account/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getZeroPendingAccount, payload)
  if (success.status) {
    yield put({
      type: 'account/SET_STATE',
      payload: {
        loading: false,
        pendingAccount: success?.data,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'account/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* GET_ZERO_APPROVED_ACCOUNT({ payload }) {
  yield put({
    type: 'account/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getZeroApprovedAccount, payload)
  if (success.status) {
    yield put({
      type: 'account/SET_STATE',
      payload: {
        loading: false,
        approvedAccount: success?.data,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'account/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* GET_ZERO_CANCELLED_ACCOUNT({ payload }) {
  yield put({
    type: 'account/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getZeroCancelledAccount, payload)
  if (success.status) {
    yield put({
      type: 'account/SET_STATE',
      payload: {
        loading: false,
        cancelledAccount: success?.data,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'account/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* GET_ZERO_ACCOUNT_DETAILS({ payload }) {
  yield put({
    type: 'account/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getZeroAccountDetails, payload)
  if (success.status) {
    yield put({
      type: 'account/SET_STATE',
      payload: {
        loading: false,
        detailData: success?.data,
        detailTableList: success.data.table_lists,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'account/SET_STATE',
      payload: {
        loading: false,
        detailData: {},
        detailTableList: [],
      },
    })
    notification.error({
      message: 'Error!',
      description: success?.message,
    })
  }
}

export function* APPROVED_DECLINE_ZERO_ACCOUNT({ payload }) {
  yield put({
    type: 'account/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.approveDeclineZeroAccount, payload)
  if (success.status) {
    yield put({
      type: 'account/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.success({
      message: success?.message,
    })
    yield put({
      type: 'account/GET_ZERO_ACCOUNT_DETAILS',
      payload: { id: payload?.get_selection[0], data: { search_term: '' } },
    })
    yield put({ type: 'account/GET_ZERO_ACCOUNT_SUMMARY' })
    yield put({ type: 'account/GET_ZERO_PENDING_ACCOUNT' })
    yield put({ type: 'account/GET_ZERO_APPROVED_ACCOUNT' })
    yield put({ type: 'account/GET_ZERO_CANCELLED_ACCOUNT' })
  }
  if (!success.status) {
    yield put({
      type: 'account/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success?.message,
    })
  }
}


export function* SET_TAB_KEY({ payload }) {
  yield put({
    type: 'account/SET_STATE',
    payload: {
      tabKey: payload,
    },
  })
}

export default function* rootSaga() {
  yield all([takeEvery(actions.SET_TAB_KEY, SET_TAB_KEY)])
  yield all([takeEvery(actions.GET_ZERO_ACCOUNT_SUMMARY, GET_ZERO_ACCOUNT_SUMMARY)])
  yield all([takeEvery(actions.GET_ZERO_PENDING_ACCOUNT, GET_ZERO_PENDING_ACCOUNT)])
  yield all([takeEvery(actions.GET_ZERO_APPROVED_ACCOUNT, GET_ZERO_APPROVED_ACCOUNT)])
  yield all([takeEvery(actions.GET_ZERO_CANCELLED_ACCOUNT, GET_ZERO_CANCELLED_ACCOUNT)])
  yield all([takeEvery(actions.GET_ZERO_ACCOUNT_DETAILS, GET_ZERO_ACCOUNT_DETAILS)])
  yield all([takeEvery(actions.APPROVED_DECLINE_ZERO_ACCOUNT, APPROVED_DECLINE_ZERO_ACCOUNT)])
}
