/* eslint-disable camelcase */
import { notification } from 'antd'
import { all, takeEvery, put, call } from 'redux-saga/effects'
import * as jwt from 'services/jwt'
import actions from './actions'

const jwts = {
  getZeroWithdrawalSummary: jwt.getZeroWithdrawalSummary,
  getZeroPendingWithdrawal: jwt.getZeroPendingWithdrawal,
  getAllWithdraw: jwt.getAllWithdraw,
  getPendingWithdrawal: jwt.getPendingWithdrawal,
  getZeroProcessingWithdrawal: jwt.getZeroProcessingWithdrawal,
  getZeroCompletedWithdrawal: jwt.getZeroCompletedWithdrawal,
  getZeroWithdrawalDetails: jwt.getZeroWithdrawalDetails,
  approveDeclineZeroWithdrawal: jwt.approveDeclineZeroWithdrawal,
  approveDeclineWithdrawal: jwt.approveDeclineWithdrawal,
}

export function* GET_ZERO_WITHDRAWAL_SUMMARY() {
  yield put({
    type: 'withdrawals/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getZeroWithdrawalSummary)
  if (success.status) {
    yield put({
      type: 'withdrawals/SET_STATE',
      payload: {
        loading: false,
        withdrawalsData: success?.data,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'withdrawals/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success.message,
    })
  }
}

export function* GET_ALL_WITHDRAWAL({ payload }) {
  yield put({
    type: 'withdrawals/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getAllWithdraw, payload)
  if (success.status) {
    yield put({
      type: 'withdrawals/SET_STATE',
      payload: {
        loading: false,
        allWithdrawal: success?.data,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'withdrawals/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* GET_ZERO_PENDING_WITHDRAWAL({ payload }) {
  yield put({
    type: 'withdrawals/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getZeroPendingWithdrawal, payload)
  if (success.status) {
    yield put({
      type: 'withdrawals/SET_STATE',
      payload: {
        loading: false,
        pendingWithdrawal: success?.data,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'withdrawals/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* GET_PENDING_WITHDRAWAL({ payload }) {
  yield put({
    type: 'withdrawals/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getPendingWithdrawal, payload)
  if (success.status) {
    yield put({
      type: 'withdrawals/SET_STATE',
      payload: {
        loading: false,
        pendingWithdrawalData: success?.data,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'withdrawals/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}
export function* GET_WITHDRAWAL_DETAIL({ payload }) {
  yield put({
    type: 'withdrawals/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getZeroWithdrawalDetails, payload)
  if (success.status) {
    yield put({
      type: 'withdrawals/SET_STATE',
      payload: {
        loading: false,
        withdrawalDetail: success?.data?.withdraw_details,
        withdrawalAdmin: success?.data?.admins,
        // withdrawalsGuarantor: success?.data?.guarantors,
        withdrawalDetailStatus: success?.data?.withdraw_status,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'withdrawals/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
    })
  }
}
export function* APPROVED_DECLINE_WITHDRAWAL({ payload }) {
  yield put({
    type: 'withdrawals/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.approveDeclineWithdrawal, payload)
   if (success.status) {
    yield put({
      type: 'withdrawals/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.success({
      message: success?.message,
    })
    yield put({ type: 'withdrawals/GET_ZERO_WITHDRAWAL_SUMMARY' })
    yield put({
      type: 'withdrawals/GET_PENDING_WITHDRAWAL',
      payload: { search_term: '', tab_no: 0 },
    })
    yield put({
      type: 'withdrawals/GET_ZERO_PENDING_WITHDRAWAL',
      payload: { search_term: '', tab_no: 1 },
    })
    yield put({
      type: 'withdrawals/GET_ZERO_PROCESSING_WITHDRAWAL',
      payload: { search_term: '', tab_no: 2 },
    })
    yield put({
      type: 'withdrawals/GET_ZERO_COMPLETED_WITHDRAWAL',
      payload: { search_term: '', tab_no: 3 },
    })
    yield put({ type: 'withdrawals/GET_WITHDRAWAL_DETAIL', payload: payload?.withdrawal_id })
  }
  if (!success.status) {
    yield put({
      type: 'refund/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success.message,
    })
  }
}

export function* GET_ZERO_PROCESSING_WITHDRAWAL({ payload }) {
  yield put({
    type: 'withdrawals/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getZeroProcessingWithdrawal, payload)
  if (success.status) {
    yield put({
      type: 'withdrawals/SET_STATE',
      payload: {
        loading: false,
        processingWithdrawal: success?.data,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'withdrawals/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* GET_ZERO_COMPLETED_WITHDRAWAL({ payload }) {
  yield put({
    type: 'withdrawals/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getZeroCompletedWithdrawal, payload)
  if (success.status) {
    yield put({
      type: 'withdrawals/SET_STATE',
      payload: {
        loading: false,
        completedWithdrawal: success?.data,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'withdrawals/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* APPROVED_DECLINE_ZERO_WITHDRAWAL({ payload }) {
  yield put({
    type: 'withdrawals/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const success = yield call(jwts.approveDeclineZeroWithdrawal, payload)
  if (success.status) {
    yield put({
      type: 'withdrawals/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.success({
      message: success?.message,
    })
    yield put({ type: 'withdrawals/GET_ZERO_WITHDRAWAL_SUMMARY' })
    yield put({
      type: 'withdrawals/GET_ZERO_PENDING_WITHDRAWAL',
      payload: { search_term: '', tab_no: 1 },
    })
    yield put({
      type: 'withdrawals/GET_ZERO_PROCESSING_WITHDRAWAL',
      payload: { search_term: '', tab_no: 2 },
    })
    yield put({
      type: 'withdrawals/GET_ZERO_COMPLETED_WITHDRAWAL',
      payload: { search_term: '', tab_no: 3 },
    })
  }
  if (!success.status) {
    yield put({
      type: 'withdrawals/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success?.message,
    })
  }
}

export function* SET_TAB_KEY({ payload }) {
  yield put({
    type: 'withdrawals/SET_STATE',
    payload: {
      tabKey: payload,
    },
  })
}

export default function* rootSaga() {
  yield all([takeEvery(actions.SET_TAB_KEY, SET_TAB_KEY)])
  yield all([takeEvery(actions.GET_ZERO_WITHDRAWAL_SUMMARY, GET_ZERO_WITHDRAWAL_SUMMARY)])
  yield all([takeEvery(actions.GET_WITHDRAWAL_DETAIL, GET_WITHDRAWAL_DETAIL)])
  yield all([takeEvery(actions.GET_PENDING_WITHDRAWAL, GET_PENDING_WITHDRAWAL)])
  yield all([takeEvery(actions.GET_ZERO_PENDING_WITHDRAWAL, GET_ZERO_PENDING_WITHDRAWAL)])
  yield all([takeEvery(actions.GET_ALL_WITHDRAWAL, GET_ALL_WITHDRAWAL)])
  yield all([takeEvery(actions.GET_ZERO_PROCESSING_WITHDRAWAL, GET_ZERO_PROCESSING_WITHDRAWAL)])
  yield all([takeEvery(actions.GET_ZERO_COMPLETED_WITHDRAWAL, GET_ZERO_COMPLETED_WITHDRAWAL)])
  yield all([takeEvery(actions.APPROVED_DECLINE_ZERO_WITHDRAWAL, APPROVED_DECLINE_ZERO_WITHDRAWAL)])
  yield all([takeEvery(actions.APPROVED_DECLINE_WITHDRAWAL, APPROVED_DECLINE_WITHDRAWAL)])
}
