const actions = {
  SET_STATE: 'target/SET_STATE',
  SET_TAB_KEY: 'target/SET_TAB_KEY',
  GET_ZERO_TARGET_SUMMARY: 'target/GET_ZERO_TARGET_SUMMARY',
  DISPLAY_ONGOING_TARGET_SAVINGS_ACCOUNT: 'target/DISPLAY_ONGOING_TARGET_SAVINGS_ACCOUNT',
  DISPLAY_COMPLETED_TARGET_SAVINGS_ACCOUNT: 'target/DISPLAY_COMPLETED_TARGET_SAVINGS_ACCOUNT',
  GET_TARGET_HISTORY_TABLE: 'target/GET_TARGET_HISTORY_TABLE',
  GET_TARGET_AUTOCOMPLETE: 'target/GET_TARGET_AUTOCOMPLETE',
  GET_SINGLE_TARGET_TRANS: 'target/GET_SINGLE_TARGET_TRANS',
}

export default actions
