import actions from './actions'

const initialState = {
  loading: false,
  data: {},
  tabKey: "1",
  pendingAccount: [],
  approvedAccount: [],
  cancelledAccount: [],
  detailData: {},
  detailTableList: [],
  memberList: [],
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
