const actions = {
  SET_STATE: 'refund/SET_STATE',
  SET_TAB_KEY: 'refund/SET_TAB_KEY',
  GET_ZERO_PENDING_REFUND: 'refund/GET_ZERO_PENDING_REFUND',
  GET_ZERO_CLOSURE: 'refund/GET_ZERO_CLOSURE',
  GET_ZERO_APPROVED_REFUND: 'refund/GET_ZERO_APPROVED_REFUND',
  GET_ZERO_CLAIMED_REFUND: 'refund/GET_ZERO_CLAIMED_REFUND',
  GET_ZERO_CANCELLED_REFUND: 'refund/GET_ZERO_CANCELLED_REFUND',
  GET_ZERO_PROCESSING_REFUND: 'refund/GET_ZERO_PROCESSING_REFUND',
  GET_ZERO_DISBURSED_REFUND: 'refund/GET_ZERO_DISBURSED_REFUND',
  GET_ZERO_REFUND_SUMMARY: 'refund/GET_ZERO_REFUND_SUMMARY',
  GET_ZERO_REFUND_REQUEST: 'refund/GET_ZERO_REFUND_REQUEST',
  GET_ZERO_REFUND_DETAIL: 'refund/GET_ZERO_REFUND_DETAIL',
  APPROVE_DECLINE_REFUND: 'refund/APPROVE_DECLINE_REFUND',
  PROCESS_REFUND: 'refund/PROCESS_REFUND',
  UPLOAD_REFUND: 'refund/UPLOAD_REFUND',
  CLOSE_REFUND: 'refund/CLOSE_REFUND',
  GET_CLOSURE_DETAIL: 'refund/GET_CLOSURE_DETAIL',
  APPROVE_CLOSURE: 'refund/APPROVE_CLOSURE',
  PROCESS_CLOSURE: 'refund/PROCESS_CLOSURE',
  EDIT_CLOSURE_FEE: 'refund/EDIT_CLOSURE_FEE',
}

export default actions
