const actions = {
  SET_STATE: 'withdrawals/SET_STATE',
  SET_TAB_KEY: 'withdrawals/SET_TAB_KEY',
  GET_ZERO_WITHDRAWAL_SUMMARY: 'withdrawals/GET_ZERO_WITHDRAWAL_SUMMARY',
  GET_ZERO_PENDING_WITHDRAWAL: 'withdrawals/GET_ZERO_PENDING_WITHDRAWAL',
  GET_ALL_WITHDRAWAL: 'withdrawals/GET_ALL_WITHDRAWAL',
  GET_PENDING_WITHDRAWAL: 'withdrawals/GET_PENDING_WITHDRAWAL',
  GET_ZERO_PROCESSING_WITHDRAWAL: 'withdrawals/GET_ZERO_PROCESSING_WITHDRAWAL',
  GET_ZERO_COMPLETED_WITHDRAWAL: 'withdrawals/GET_ZERO_COMPLETED_WITHDRAWAL',
  GET_WITHDRAWAL_DETAIL: 'withdrawals/GET_WITHDRAWAL_DETAIL',
  APPROVED_DECLINE_WITHDRAWAL: 'withdrawals/APPROVED_DECLINE_WITHDRAWAL',
  APPROVED_DECLINE_ZERO_WITHDRAWAL: 'withdrawals/APPROVED_DECLINE_ZERO_WITHDRAWAL',
}

export default actions
