const actions = {
  SET_STATE: 'user/SET_STATE',
  LOGIN: 'user/LOGIN',
  SUBMIT_TOKEN: 'user/SUBMIT_TOKEN',
  REGISTER: 'user/REGISTER',
  LOAD_CURRENT_ACCOUNT: 'user/LOAD_CURRENT_ACCOUNT',
  LOGOUT: 'user/LOGOUT',
  UPLOAD_EDIT_USER: 'user/UPLOAD_EDIT_USER',
}

export default actions
