const actions = {
  SET_STATE: 'settings/SET_STATE',
  CHANGE_SETTING: 'settings/CHANGE_SETTING',
  SET_PRIMARY_COLOR: 'settings/SET_PRIMARY_COLOR',
  SET_THEME: 'settings/SET_THEME',
  GET_ALL_ROLES: 'settings/GET_ALL_ROLES',
  GET_ALL_PERMISSIONS: 'settings/GET_ALL_PERMISSIONS',
  GET_ALL_ZERO_STAFFS: 'settings/GET_ALL_ZERO_STAFFS',
  CREATE_ZERO_ROLE: 'settings/CREATE_ZERO_ROLE',
  GET_SINGLE_ROLE_DETAILS: 'settings/GET_SINGLE_ROLE_DETAILS',
  UPDATE_SINGLE_ROLE_DETAILS: 'settings/UPDATE_SINGLE_ROLE_DETAILS',
  DELETE_ZERO_ROLE: 'settings/DELETE_ZERO_ROLE',
  CREATE_ZERO_STAFF: 'settings/CREATE_ZERO_STAFF',
  GET_ZERO_STAFF_DETAILS: 'settings/GET_ZERO_STAFF_DETAILS',
  UPDATE_STAFF_DETAILS: 'settings/UPDATE_STAFF_DETAILS',
  ENABLE_DISABLE_ZERO_STAFF: 'settings/ENABLE_DISABLE_ZERO_STAFF',
  SUBMIT_LOAN_DURATION: 'settings/SUBMIT_LOAN_DURATION',
  DISPLAY_LOAN_DURATION: 'settings/DISPLAY_LOAN_DURATION',
  SUBMIT_PROCUREMENT_DURATION: 'settings/SUBMIT_PROCUREMENT_DURATION',
  DISPLAY_PROCUREMENT_DURATION: 'settings/DISPLAY_PROCUREMENT_DURATION',
  GET_ALL_ORGANIZATIONS: 'settings/GET_ALL_ORGANIZATIONS',
  CREATE_ORGANIZATION: 'settings/CREATE_ORGANIZATION',
  DEACTIVATE_ORGANIZATION: 'settings/DEACTIVATE_ORGANIZATION',
  GET_API_KEYS: 'settings/GET_API_KEYS',
  CREATE_API_KEY: 'settings/CREATE_API_KEY',
  TOGGLE_API_KEY: 'settings/TOGGLE_API_KEY',
}

export default actions
