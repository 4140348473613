/* eslint-disable camelcase */
import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
// import store from 'store'
// import { history } from 'index'
import * as jwt from 'services/jwt'
import actions from './actions'

const jwts = {
  getUserReportList: jwt.getUserReportList,
  getUserReportBalance: jwt.getUserReportBalance,
  getUserReportTransactions: jwt.getUserReportTransactions,
}

export function* GET_REPORT_LIST({ payload }) {
    yield put({
      type: 'reports/SET_STATE',
      payload: {
        loading: true,
      },
    })
    const success = yield call(jwts.getUserReportList, payload)
    if (success.status) {
      yield put({
        type: 'reports/SET_STATE',
        payload: {
          loading: false,
          reportList: success?.data,
        },
      })
    }
    if (!success.status) {
      yield put({
        type: 'reports/SET_STATE',
        payload: {
          loading: false,
        },
      })
      notification.error({
        message: 'Error!',
        description: success.message,
      })
    }
  }


  export function* GET_REPORT_BALANCE({ payload }) {
    yield put({
      type: 'reports/SET_STATE',
      payload: {
        loadings: true,
      },
    })
    const success = yield call(jwts.getUserReportBalance, payload)
    if (success.status) {
      yield put({
        type: 'reports/SET_STATE',
        payload: {
          loadings: false,
          reportBalance: success,
        },
      })
    }
    if (!success.status) {
      yield put({
        type: 'reports/SET_STATE',
        payload: {
          loadings: false,
        },
      })
      notification.error({
        message: 'Error!',
        description: success.message,
      })
    }
  }

  export function* GET_REPORT_TRANSACTIONS({ payload }) {
    yield put({
      type: 'reports/SET_STATE',
      payload: {
        loading: true,
      },
    })
    const success = yield call(jwts.getUserReportTransactions, payload)
    if (success.status) {
      yield put({
        type: 'reports/SET_STATE',
        payload: {
          loading: false,
          reportTransactions: success?.data,
        },
      })
    }
    if (!success.status) {
      yield put({
        type: 'reports/SET_STATE',
        payload: {
          loading: false,
        },
      })
      notification.error({
        message: 'Error!',
        description: success.message,
      })
    }
  }


export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_REPORT_LIST, GET_REPORT_LIST),
    takeEvery(actions.GET_REPORT_BALANCE, GET_REPORT_BALANCE),
    takeEvery(actions.GET_REPORT_TRANSACTIONS, GET_REPORT_TRANSACTIONS),
  ])
}
