import 'antd/lib/style/index.less' // antd core styles
// import './components/kit/vendors/antd/themes/default.less' // default theme antd components
// import './components/kit/vendors/antd/themes/dark.less' // dark theme antd components
import 'antd/dist/antd.css'
import './global.scss' // app & third-party component styles
import './general.scss' // app & third-party component styles
// import './general.scss' // app & third-party miscellaneous styles
import 'bootstrap/dist/css/bootstrap.min.css'


import React from 'react'
import ReactDOM from 'react-dom'
import { createHashHistory } from 'history'
import { createStore, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
// import { logger } from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import { routerMiddleware } from 'connected-react-router'

import reducers from './redux/reducers'
import sagas from './redux/sagas'
import Localization from './localization'
import Router from './router'
import * as serviceWorker from './serviceWorker'

// mocking api
import 'services/axios/fakeApi'

// middlewared
const history = createHashHistory()
const sagaMiddleware = createSagaMiddleware()
const routeMiddleware = routerMiddleware(history)
const middlewares = [sagaMiddleware, routeMiddleware]
// if (process.env.NODE_ENV === 'development') {
//   middlewares.push(logger)
// }
const store = createStore(reducers(history), compose(applyMiddleware(...middlewares)))
sagaMiddleware.run(sagas)

ReactDOM.render(
  <Provider store={store}>
    <Localization>
      <Router history={history} />
    </Localization>
  </Provider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
export { store, history }



// const handleRegister = (e) => {
//   e.preventDefault()
//   const success = document.getElementById("success_alert")
//   const button = document.getElementById("submit_button")
//   const error = document.getElementById("error_alert")
//   const payload = {
//     first_name: document.getElementById("first_name").value,
//     second_name: document.getElementById("fir_name").value,
//   }

//   try{
//     button.innerHTML = "Loading..."
//     fetch("", {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify(payload),
//     }).then(() => {success.style.display = "block"})
//   }catch(err){
//     error.style.display = "block"
//     error.innerHTML = err?.message || "Something went wrong!"
//   }
//   button.innerHTML = "Submit"
// }

// try{
//   fetch("").then(({data}) => {console.log(data)})
// }catch(err){
//   console.log(err)
// }