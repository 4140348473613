/* eslint-disable camelcase */
import { notification } from 'antd'
import { all, takeEvery, put, call } from 'redux-saga/effects'
import * as jwt from 'services/jwt'
import actions from './actions'

const jwts = {
  getZeroDashboard: jwt.getZeroDashboard,
}

export function* GET_ZERO_DASHBOARD() {
  yield put({
    type: 'dashboard/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getZeroDashboard)
  if (success.status) {
    yield put({
      type: 'dashboard/SET_STATE',
      payload: {
        loading: false,
        data: success?.data?.widget_summary,
        activeLoan: success?.data?.active_loan,
        activeProcurement: success?.data?.active_procurements,
        recentTransaction: success?.data?.recent_transactions,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'dashboard/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: "Error!"
    })
  }
}


export default function* rootSaga() {
  yield all([takeEvery(actions.GET_ZERO_DASHBOARD, GET_ZERO_DASHBOARD)])
}
