/* eslint-disable camelcase */
import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification, message } from 'antd'
// import store from 'store'
// import { history } from 'index'
import * as jwt from 'services/jwt'
import actions from './actions'

const jwts = {
  getZeroTargetSummary: jwt.getZeroTargetSummary,
  displayOngoingTargetSavingsAccount: jwt.displayOngoingTargetSavingsAccount,
  displayCompletedTargetSavingsAccount: jwt.displayCompletedTargetSavingsAccount,
  getTargetHistoryTable: jwt.getTargetHistoryTable,
  getTargetTransDetail: jwt.getTargetTransDetail,
  getTargetAutocomplete: jwt.getTargetAutocomplete,
}

export function* GET_TARGET_AUTOCOMPLETE({ payload }) {
  const success = yield call(jwts.getTargetAutocomplete, payload)
  if (success.status) {
    yield put({
      type: 'target/SET_STATE',
      payload: {
        targetAutocomplete: success.data,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'share/SET_STATE',
      payload: {
        targetAutocomplete: [],
      },
    })
    message.error({
      content: success.message,
      key: 'updatable',
    })
  }
}

export function* GET_TARGET_HISTORY_TABLE({ payload }) {
  yield put({
    type: 'target/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getTargetHistoryTable, payload)
  if (success.status) {
    yield put({
      type: 'target/SET_STATE',
      payload: {
        loading: false,
        targetHistory: success?.data,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'target/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: success.message,
    })
  }
}

export function* GET_SINGLE_TARGET_TRANS ({ payload }) {
  yield put({
    type: 'target/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getTargetTransDetail, payload)
  if (success.status) {
    yield put({
      type: 'target/SET_STATE',
      payload: {
        loading: false,
        payDetails: success?.data
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'target/SET_STATE',
      payload: {
        loading: false,
      },
    })
    message.error({
      message: 'Error!',
    })
  }
}

export function* GET_ZERO_TARGET_SUMMARY({ payload }) {
  yield put({
    type: 'target/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getZeroTargetSummary, payload)
  if (success.status) {
    yield put({
      type: 'target/SET_STATE',
      payload: {
        loading: false,
        userSummary: success?.data?.user_summary,
        targetSummary: success?.data?.target_summary,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'target/SET_STATE',
      payload: {
        loading: false,
      },
    })
    message.error({
      message: success.message,
    })
  }
}

export function* DISPLAY_ONGOING_TARGET_SAVINGS_ACCOUNT({ payload }) {
  console.log(payload)
  yield put({
    type: 'target/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.displayOngoingTargetSavingsAccount, payload)
  if (success.status) {
    yield put({
      type: 'target/SET_STATE',
      payload: {
        loading: false,
        ongoingTargetSavings: success?.data,
      },
    })
  }

  if (!success.status) {
    yield put({
      type: 'target/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success.message,
    })
  }
}

export function* DISPLAY_COMPLETED_TARGET_SAVINGS_ACCOUNT({ payload }) {
  yield put({
    type: 'target/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.displayCompletedTargetSavingsAccount, payload)
  if (success.status) {
    yield put({
      type: 'target/SET_STATE',
      payload: {
        loading: false,
        completedTargetSavings: success?.data,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'target/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success.message,
    })
  }
}

export function* SET_TAB_KEY({ payload }) {
  yield put({
    type: 'target/SET_STATE',
    payload: {
      tabKey: payload,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.SET_TAB_KEY, SET_TAB_KEY),
    takeEvery(actions.GET_TARGET_HISTORY_TABLE, GET_TARGET_HISTORY_TABLE),
    takeEvery(actions.GET_ZERO_TARGET_SUMMARY, GET_ZERO_TARGET_SUMMARY),
    takeEvery(actions.GET_SINGLE_TARGET_TRANS, GET_SINGLE_TARGET_TRANS),
    takeEvery(
      actions.DISPLAY_ONGOING_TARGET_SAVINGS_ACCOUNT,
      DISPLAY_ONGOING_TARGET_SAVINGS_ACCOUNT,
    ),
    takeEvery(
      actions.DISPLAY_COMPLETED_TARGET_SAVINGS_ACCOUNT,
      DISPLAY_COMPLETED_TARGET_SAVINGS_ACCOUNT,
    ),
    takeEvery(actions.GET_TARGET_AUTOCOMPLETE, GET_TARGET_AUTOCOMPLETE),
  ])
}
