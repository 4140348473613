import actions from './actions'

const initialState = {
  loading: false,
  data: {},
  transactionCategory: [],
  userTransactions: [],
  uploadedData: [],
  uploadHistory: [],
  doubleHistory: [],
  checkedExcelData: [],
  excelDetail: {},
  excelAdmin: [],
  excelDetailStatus: "",
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
