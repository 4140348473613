import actions from './actions'

const initialState = {
  loading: false,
  userSummary: {},
  targetSummary: {},
  targetHistory: [],
  payDetails:[],
  tabKey: '1',
  procureRepays:[],
  targetAutocomplete: [],
  ongoingTargetSavings: [],
  completedTargetSavings: [],
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
