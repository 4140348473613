/* eslint-disable camelcase */
import { notification } from 'antd'
import { all, takeEvery, put, call } from 'redux-saga/effects'
import * as jwt from 'services/jwt'
import actions from './actions'

const jwts = {
  getZeroMembers: jwt.getZeroMembers,
  getZeroIncompleteMembers: jwt.getZeroIncompleteMembers,
  getZeroProcureMembers: jwt.getZeroProcureMembers,
  getZeroInactiveMembers: jwt.getZeroInactiveMembers,
  getZeroMemberDetails: jwt.getZeroMemberDetails,
  setZeroMemberDetails: jwt.setZeroMemberDetails,
  editMembershipType: jwt.editMembershipType,
  setZeroMemberStatus: jwt.setZeroMemberStatus,
  addZeroMember: jwt.addZeroMember,
  importZeroMember: jwt.importZeroMember,
  enableDisableZeroMember: jwt.enableDisableZeroMember,
  resendEmailLink: jwt.resendEmailLink,
  loanProcureAck: jwt.loanProcureAck,
  getZeroMemberSavingDetail: jwt.getZeroMemberSavingDetail,
  getZeroMemberGuaranteedLoan: jwt.getZeroMemberGuaranteedLoan,
  getZeroMemberSavingTrans: jwt.getZeroMemberSavingTrans,
  getZeroMemberTargetDetail: jwt.getZeroMemberTargetDetail,
  getZeroMemberTargetTrans: jwt.getZeroMemberTargetTrans,
  getZeroMemberShareDetail: jwt.getZeroMemberShareDetail,
  getZeroMemberShareTrans: jwt.getZeroMemberShareTrans,
  getZeroMemberLoanDetail: jwt.getZeroMemberLoanDetail,
  getZeroMemberLoanTrans: jwt.getZeroMemberLoanTrans,
  getZeroMemberProcurementDetail: jwt.getZeroMemberProcurementDetail,
  getZeroMemberProcurementTrans: jwt.getZeroMemberProcurementTrans,
  getZeroMemberWalletTrans: jwt.getZeroMemberWalletTrans,
  getZeroMemberWithdrawalWaiver: jwt.getZeroMemberWithdrawalWaiver,
  setZeroMemberWithdrawalWaiver: jwt.setZeroMemberWithdrawalWaiver,
  setZeroMemberLoanWaiver: jwt.setZeroMemberLoanWaiver,
  getZeroMemberPackageDebt: jwt.getZeroMemberPackageDebt,
  moveZeroMemberPackageDebt: jwt.moveZeroMemberPackageDebt,
  setZeroMemberProcurementWaiver: jwt.setZeroMemberProcurementWaiver,
  interSavingsTransfer: jwt.interSavingsTransfer,
}

export function* GET_ZERO_MEMBER({ payload }) {
  yield put({
    type: 'member/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getZeroMembers, payload)
  if (success.status) {
    yield put({
      type: 'member/SET_STATE',
      payload: {
        loading: false,
        data: success?.data,
        memberList: success.data.members_lists,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'member/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* GET_ZERO_INACTIVE_MEMBER({ payload }) {
  yield put({
    type: 'member/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getZeroInactiveMembers, payload)
  if (success.status) {
    yield put({
      type: 'member/SET_STATE',
      payload: {
        loading: false,
        data: success?.data,
        memberList: success.data.members_lists,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'member/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* GET_ZERO_INCOMPLETE_MEMBER({ payload }) {
  yield put({
    type: 'member/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getZeroIncompleteMembers, payload)
  if (success.status) {
    yield put({
      type: 'member/SET_STATE',
      payload: {
        loading: false,
        data: success?.data,
        memberList: success.data.members_lists,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'member/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* GET_ZERO_PROCURE_MEMBER({ payload }) {
  yield put({
    type: 'member/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getZeroProcureMembers, payload)
  if (success.status) {
    yield put({
      type: 'member/SET_STATE',
      payload: {
        loading: false,
        data: success?.data,
        memberList: success.data.members_lists,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'member/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* GET_ZERO_MEMBER_DETAILS({ payload }) {
  yield put({
    type: 'member/SET_STATE',
    payload: {
      loading: true,
      userDetailsId: payload,
    },
  })
  const success = yield call(jwts.getZeroMemberDetails, payload)
  if (success.status) {
    yield put({
      type: 'member/SET_STATE',
      payload: {
        loading: false,
        memberDetails: success?.data?.profile[0],
        userDetailsId: success?.data?.user_id,
        closureInfo: success?.data?.user_closure_info,
        userBankDetails: success?.data?.bank_details,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'member/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* ADD_ZERO_MEMBER({ payload }) {
  yield put({
    type: 'member/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.addZeroMember, payload)
  if (success.status) {
    yield put({
      type: 'member/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.success({
      message: success.message,
    })
  }
  if (!success.status) {
    yield put({
      type: 'member/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: success.message,
    })
  }
}

export function* IMPORT_ZERO_MEMBER({ payload }) {
  yield put({
    type: 'member/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.importZeroMember, payload)
  if (success.status) {
    yield put({
      type: 'member/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.success({
      message: success.message,
    })
  }
  if (!success.status) {
    yield put({
      type: 'member/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: success.message,
    })
  }
}

export function* ENABLE_DISABLE_ZERO_MEMBER({ payload }) {
  yield put({
    type: 'member/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.enableDisableZeroMember, payload)
  if (success.status) {
    yield put({
      type: 'member/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.success({
      message: success.message,
    })
    yield put({ type: 'member/GET_ZERO_MEMBER' })
    yield put({ type: 'member/GET_ZERO_MEMBER_DETAILS', payload: payload?.get_selection[0] })
  }
  if (!success.status) {
    yield put({
      type: 'member/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: success.message,
    })
  }
}

export function* RESEND_EMAIL_LINK({ payload }) {
  yield put({
    type: 'member/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.resendEmailLink, payload)
  if (success.status) {
    yield put({
      type: 'member/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.success({
      message: success.message,
    })
    yield put({ type: 'member/GET_ZERO_MEMBER' })
  }
  if (!success.status) {
    yield put({
      type: 'member/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: success.message,
    })
  }
}

export function* PROCURE_LOAN_ACK({ payload }) {
  console.log(payload)
  yield put({
    type: 'member/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.loanProcureAck, payload)
  if (success.status) {
    yield put({
      type: 'member/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.success({
      message: success.message,
    })
    yield put({ type: 'member/GET_ZERO_MEMBER' })
  }
  if (!success.status) {
    yield put({
      type: 'member/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: success.message,
    })
  }
}

export function* GET_ZERO_MEMBER_SAVING_DETAIL({ payload }) {
  yield put({
    type: 'member/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getZeroMemberSavingDetail, payload)
  if (success.status) {
    yield put({
      type: 'member/SET_STATE',
      payload: {
        loading: false,
        memberSavingDetail: success?.data,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'member/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* GET_ZERO_MEMBER_GUARANTEE_LOAN({ payload }) {
  yield put({
    type: 'member/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getZeroMemberGuaranteedLoan, payload)
  if (success.status) {
    yield put({
      type: 'member/SET_STATE',
      payload: {
        loading: false,
        guaranteedLoan: success?.data,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'member/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* GET_ZERO_MEMBER_SAVING_TRANS({ payload }) {
  yield put({
    type: 'member/SET_STATE',
    payload: {
      savingTransLoading: true,
    },
  })
  const success = yield call(jwts.getZeroMemberSavingTrans, payload)
  if (success.status) {
    yield put({
      type: 'member/SET_STATE',
      payload: {
        savingTransLoading: false,
        memberSavingTrans: success?.data,
        credit: success?.credit,
        debit: success?.debit,
        filterBalance: success?.filteredBalance,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'member/SET_STATE',
      payload: {
        savingTransLoading: false,
      },
    })
  }
}

export function* GET_ZERO_MEMBER_TARGET_DETAIL({ payload }) {
  yield put({
    type: 'member/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getZeroMemberTargetDetail, payload)
  if (success.status) {
    yield put({
      type: 'member/SET_STATE',
      payload: {
        loading: false,
        memberTargetDetail: success?.data,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'member/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* GET_ZERO_MEMBER_TARGET_TRANS({ payload }) {
  yield put({
    type: 'member/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getZeroMemberTargetTrans, payload)
  if (success.status) {
    yield put({
      type: 'member/SET_STATE',
      payload: {
        loading: false,
        memberTargetTrans: success?.data,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'member/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* GET_ZERO_MEMBER_SHARE_DETAIL({ payload }) {
  yield put({
    type: 'member/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getZeroMemberShareDetail, payload)
  if (success.status) {
    yield put({
      type: 'member/SET_STATE',
      payload: {
        loading: false,
        memberShareDetail: success?.data,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'member/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* GET_ZERO_MEMBER_SHARE_TRANS({ payload }) {
  yield put({
    type: 'member/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getZeroMemberShareTrans, payload)
  if (success.status) {
    yield put({
      type: 'member/SET_STATE',
      payload: {
        loading: false,
        memberShareTrans: success?.data,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'member/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* GET_ZERO_MEMBER_LOAN_DETAIL({ payload }) {
  yield put({
    type: 'member/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getZeroMemberLoanDetail, payload)
  if (success.status) {
    yield put({
      type: 'member/SET_STATE',
      payload: {
        loading: false,
        memberLoanDetail: success?.data,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'member/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* GET_ZERO_MEMBER_LOAN_TRANS({ payload }) {
  yield put({
    type: 'member/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getZeroMemberLoanTrans, payload)
  if (success.status) {
    yield put({
      type: 'member/SET_STATE',
      payload: {
        loading: false,
        memberLoanTrans: success?.data,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'member/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* GET_ZERO_MEMBER_PROCUREMENT_DETAIL({ payload }) {
  yield put({
    type: 'member/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getZeroMemberProcurementDetail, payload)
  if (success.status) {
    yield put({
      type: 'member/SET_STATE',
      payload: {
        loading: false,
        memberProcurementDetail: success?.data,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'member/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* GET_ZERO_MEMBER_PROCUREMENT_TRANS({ payload }) {
  yield put({
    type: 'member/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getZeroMemberProcurementTrans, payload)
  if (success.status) {
    yield put({
      type: 'member/SET_STATE',
      payload: {
        loading: false,
        memberProcurementTrans: success?.data,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'member/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* GET_ZERO_MEMBER_WALLET_TRANS({ payload }) {
  yield put({
    type: 'member/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getZeroMemberWalletTrans, payload)
  if (success.status) {
    yield put({
      type: 'member/SET_STATE',
      payload: {
        loading: false,
        memberWalletTrans: success?.data,
        memberWalletBal: success?.balance
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'member/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* GET_ZERO_MEMBER_WITHDRAWAL_WAIVER({ payload }) {
  yield put({
    type: 'member/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getZeroMemberWithdrawalWaiver, payload)
  if (success.status) {
    yield put({
      type: 'member/SET_STATE',
      payload: {
        loading: false,
        memberWithdrawalWaiver: success?.data,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'member/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* SET_ZERO_MEMBER_WITHDRAWAL_WAIVER({ payload }) {
  yield put({
    type: 'member/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.setZeroMemberWithdrawalWaiver, payload)
  if (success.status) {
    yield put({
      type: 'member/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.success({
      message: success?.message,
    })
    yield put({
      type: 'member/GET_ZERO_MEMBER_WITHDRAWAL_WAIVER',
      payload: payload?.user_id,
    })
  }
  if (!success.status) {
    yield put({
      type: 'member/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: success?.message,
    })
  }
}


export function* SET_ZERO_MEMBER_LOAN_WAIVER({ payload }) {
  yield put({
    type: 'member/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.setZeroMemberLoanWaiver, payload)
  if (success.status) {
    yield put({
      type: 'member/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.success({
      message: "Success!",
      description: success?.message,
    })
  }
  if (!success.status) {
    yield put({
      type: 'member/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: "Error!",
      description: success?.message,
    })
  }
}

export function* EDIT_MEMBER_TYPE({ payload }) {
  yield put({
    type: 'member/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.editMembershipType, payload)
  if (success.status) {
    yield put({
      type: 'member/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.success({
      message: 'Success',
      description: success?.message,
    })
    yield put({
      type: 'member/GET_ZERO_MEMBER_DETAILS',
      payload: payload?.id,
    })
  }
  if (!success.status) {
    yield put({
      type: 'member/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error',
      description: success?.message,
    })
  }
}

export function* SET_ZERO_MEMBER_DETAIL({ payload }) {
  yield put({
    type: 'member/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.setZeroMemberDetails, payload)
  if (success.status) {
    yield put({
      type: 'member/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.success({
      message: 'Success',
      description: success?.message,
    })
    yield put({
      type: 'member/GET_ZERO_MEMBER_DETAILS',
      payload: payload?.id,
    })
  }
  if (!success.status) {
    yield put({
      type: 'member/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error',
      description: success?.message,
    })
  }
}

export function* GET_ZERO_MEMBER_PACKAGE_DEBT({ payload }) {
  yield put({
    type: 'member/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getZeroMemberPackageDebt, payload)
  if (success.status) {
    yield put({
      type: 'member/SET_STATE',
      payload: {
        loading: false,
        memberPackageDebt: success?.data || {},
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'member/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error',
      description: success?.message,
    })
  }
}

export function* MOVE_ZERO_MEMBER_PACKAGE_DEBT({ payload }) {
  yield put({
    type: 'member/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.moveZeroMemberPackageDebt, payload)
  if (success.status) {
    notification.success({
      message: 'Success',
      description: success?.message,
    })
    yield put({
      type: 'member/SET_STATE',
      payload: {
        loading: false,
      },
    })

    yield put({ type: 'member/GET_ZERO_MEMBER_PACKAGE_DEBT', payload: payload?.user_id })
    yield put({ type: 'member/GET_ZERO_MEMBER_SAVING_DETAIL', payload: payload?.user_id })
    yield put({
      type: 'member/GET_ZERO_MEMBER_SAVING_TRANS',
      payload: { id: payload?.user_id, data: { category: 'savings' } },
    })
  }
  if (!success.status) {
    yield put({
      type: 'member/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error',
      description: success?.message,
    })
  }
}

export function* INTER_SAVINGS_TRANSFER({ payload }) {
  console.log(payload)
  yield put({
    type: 'member/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.interSavingsTransfer, payload)
  if (success.status) {
    notification.success({
      message: 'Success',
      description: success?.message,
    })
    yield put({
      type: 'member/SET_STATE',
      payload: {
        loading: false,
      },
    })

    yield put({ type: 'member/GET_ZERO_MEMBER_PACKAGE_DEBT', payload: payload?.sender_id })
    yield put({ type: 'member/GET_ZERO_MEMBER_SAVING_DETAIL', payload: payload?.sender_id })
    yield put({
      type: 'member/GET_ZERO_MEMBER_SAVING_TRANS',
      payload: { id: payload?.sender_id, data: { category: 'savings' } },
    })
  }
  if (!success.status) {
    yield put({
      type: 'member/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error',
      description: success?.message,
    })
  }
}

export function* SET_ZERO_MEMBER_STATUS({ payload }) {
  yield put({
    type: 'member/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.setZeroMemberStatus, payload)
  if (success.status) {
    yield put({
      type: 'member/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.success({
      message: 'Success',
      description: success?.message,
    })
    yield put({
      type: 'member/GET_ZERO_MEMBER_DETAILS',
      payload,
    })
  }
  if (!success.status) {
    yield put({
      type: 'member/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error',
      description: success?.message,
    })
  }
}


export function* SET_ZERO_MEMBER_PROCUREMENT_WAIVER({ payload }) {
  yield put({
    type: 'member/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.setZeroMemberProcurementWaiver, payload)
  if (success.status) {
    yield put({
      type: 'member/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.success({
      message: 'Success',
      description: success?.message,
    })
  }
  if (!success.status) {
    yield put({
      type: 'member/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error',
      description: success?.message,
    })
  }
}

export function* SET_TAB_KEY({ payload }) {
  yield put({
    type: 'member/SET_STATE',
    payload: {
      tabKey: payload,
    },
  })
}


export default function* rootSaga() {
  yield all([takeEvery(actions.SET_TAB_KEY, SET_TAB_KEY)])
  yield all([takeEvery(actions.GET_ZERO_MEMBER, GET_ZERO_MEMBER)])
  yield all([takeEvery(actions.GET_ZERO_INACTIVE_MEMBER, GET_ZERO_INACTIVE_MEMBER)])
  yield all([takeEvery(actions.GET_ZERO_INCOMPLETE_MEMBER, GET_ZERO_INCOMPLETE_MEMBER)])
  yield all([takeEvery(actions.GET_ZERO_PROCURE_MEMBER, GET_ZERO_PROCURE_MEMBER)])
  yield all([takeEvery(actions.GET_ZERO_MEMBER_DETAILS, GET_ZERO_MEMBER_DETAILS)])
  yield all([takeEvery(actions.ADD_ZERO_MEMBER, ADD_ZERO_MEMBER)])
  yield all([takeEvery(actions.IMPORT_ZERO_MEMBER, IMPORT_ZERO_MEMBER)])
  yield all([takeEvery(actions.GET_ZERO_MEMBER_GUARANTEE_LOAN, GET_ZERO_MEMBER_GUARANTEE_LOAN)])
  yield all([takeEvery(actions.PROCURE_LOAN_ACK, PROCURE_LOAN_ACK)])
  yield all([takeEvery(actions.RESEND_EMAIL_LINK, RESEND_EMAIL_LINK)])
  yield all([takeEvery(actions.ENABLE_DISABLE_ZERO_MEMBER, ENABLE_DISABLE_ZERO_MEMBER)])
  yield all([takeEvery(actions.GET_ZERO_MEMBER_SAVING_DETAIL, GET_ZERO_MEMBER_SAVING_DETAIL)])
  yield all([takeEvery(actions.GET_ZERO_MEMBER_SAVING_TRANS, GET_ZERO_MEMBER_SAVING_TRANS)])
  yield all([takeEvery(actions.GET_ZERO_MEMBER_TARGET_DETAIL, GET_ZERO_MEMBER_TARGET_DETAIL)])
  yield all([takeEvery(actions.GET_ZERO_MEMBER_TARGET_TRANS, GET_ZERO_MEMBER_TARGET_TRANS)])
  yield all([takeEvery(actions.GET_ZERO_MEMBER_SHARE_DETAIL, GET_ZERO_MEMBER_SHARE_DETAIL)])
  yield all([takeEvery(actions.GET_ZERO_MEMBER_SHARE_TRANS, GET_ZERO_MEMBER_SHARE_TRANS)])
  yield all([takeEvery(actions.GET_ZERO_MEMBER_LOAN_DETAIL, GET_ZERO_MEMBER_LOAN_DETAIL)])
  yield all([takeEvery(actions.GET_ZERO_MEMBER_LOAN_TRANS, GET_ZERO_MEMBER_LOAN_TRANS)])
  yield all([takeEvery(actions.GET_ZERO_MEMBER_PROCUREMENT_DETAIL, GET_ZERO_MEMBER_PROCUREMENT_DETAIL)])
  yield all([takeEvery(actions.GET_ZERO_MEMBER_PROCUREMENT_TRANS, GET_ZERO_MEMBER_PROCUREMENT_TRANS)])
  yield all([takeEvery(actions.GET_ZERO_MEMBER_WALLET_TRANS, GET_ZERO_MEMBER_WALLET_TRANS)])
  yield all([takeEvery(actions.GET_ZERO_MEMBER_WITHDRAWAL_WAIVER, GET_ZERO_MEMBER_WITHDRAWAL_WAIVER)])
  yield all([takeEvery(actions.SET_ZERO_MEMBER_WITHDRAWAL_WAIVER, SET_ZERO_MEMBER_WITHDRAWAL_WAIVER)])
  yield all([takeEvery(actions.SET_ZERO_MEMBER_LOAN_WAIVER, SET_ZERO_MEMBER_LOAN_WAIVER)])
  yield all([takeEvery(actions.SET_ZERO_MEMBER_DETAIL, SET_ZERO_MEMBER_DETAIL)])
  yield all([takeEvery(actions.EDIT_MEMBER_TYPE, EDIT_MEMBER_TYPE)])
  yield all([takeEvery(actions.SET_ZERO_MEMBER_STATUS, SET_ZERO_MEMBER_STATUS)])
  yield all([takeEvery(actions.INTER_SAVINGS_TRANSFER, INTER_SAVINGS_TRANSFER)])
  yield all([takeEvery(actions.GET_ZERO_MEMBER_PACKAGE_DEBT, GET_ZERO_MEMBER_PACKAGE_DEBT)])
  yield all([takeEvery(actions.MOVE_ZERO_MEMBER_PACKAGE_DEBT, MOVE_ZERO_MEMBER_PACKAGE_DEBT)])
  yield all([takeEvery(actions.SET_ZERO_MEMBER_PROCUREMENT_WAIVER, SET_ZERO_MEMBER_PROCUREMENT_WAIVER)])
}
