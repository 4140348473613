import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import user from './user/reducers'
import menu from './menu/reducers'
import settings from './settings/reducers'
import loan from './loan/reducers'
import activity from './activity/reducers'
import member from './member/reducers'
import account from './account/reducers'
import transactions from './transactions/reducers'
import dashboard from './dashboard/reducers'
import withdrawals from './withdrawal/reducers'
import savings from './savings/reducers'
import target from './target/reducers'
import share from './share/reducers'
import procurement from './procurement/reducers'
import posting from './posting/reducers'
import backlog from './backlog/reducers'
import refund from './refund/reducers'
import reports from './reports/reducers'
import techendpay from './techendpay/reducers'
import wootransactions from './wootransactions/reducers'

export default history =>
  combineReducers({
    router: connectRouter(history),
    user,
    menu,
    savings,
    settings,
    activity,
    loan,
    member,
    account,
    transactions,
    dashboard,
    withdrawals,
    target,
    share,
    procurement,
    posting,
    backlog,
    refund,
    reports,
    techendpay,
    wootransactions,
  })
