/* eslint-disable camelcase */
import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification, message } from 'antd'
// import store from 'store'
// import { history } from 'index'
import * as jwt from 'services/jwt'
import actions from './actions'

const jwts = {
  getShareCapitalSummary: jwt.getShareCapitalSummary,
  displayShareCapitalAccount: jwt.displayShareCapitalAccount,
  getShareHistoryTable: jwt.getShareHistoryTable,
  getShareAutocomplete: jwt.getShareAutocomplete,
}

export function* GET_SHARE_HISTORY_TABLE({ payload }) {
  yield put({
    type: 'share/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getShareHistoryTable, payload)
  if (success.status) {
    yield put({
      type: 'share/SET_STATE',
      payload: {
        loading: false,
        shareHistory: success?.data,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'share/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: success.message,
    })
  }
}

export function* GET_SHARE_AUTOCOMPLETE({ payload }) {
  const success = yield call(jwts.getShareAutocomplete, payload)
  if (success.status) {
    yield put({
      type: 'share/SET_STATE',
      payload: {
        shareAutocomplete: success.data,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'share/SET_STATE',
      payload: {
        shareAutocomplete: [],
      },
    })
    message.error({
      content: success.message,
      key: 'updatable',
    })
  }
}

export function* GET_SHARE_CAPITAL_SUMMARY({ payload }) {
  yield put({
    type: 'share/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getShareCapitalSummary, payload)
  if (success.status) {
    yield put({
      type: 'share/SET_STATE',
      payload: {
        loading: false,
        userSummary: success?.data?.user_summary,
        shareCapitalSummary: success?.data?.share_capital_summary,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'share/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: success.message,
    })
  }
}

export function* DISPLAY_SHARE_CAPITAL_ACCOUNT({ payload }) {
  yield put({
    type: 'share/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.displayShareCapitalAccount, payload)
  console.log(success)
  if (success.status) {
    yield put({
      type: 'share/SET_STATE',
      payload: {
        loading: false,
        shareCapital: success?.data,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'share/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success.message,
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_SHARE_CAPITAL_SUMMARY, GET_SHARE_CAPITAL_SUMMARY),
    takeEvery(actions.GET_SHARE_HISTORY_TABLE, GET_SHARE_HISTORY_TABLE),
    takeEvery(actions.DISPLAY_SHARE_CAPITAL_ACCOUNT, DISPLAY_SHARE_CAPITAL_ACCOUNT),
    takeEvery(actions.GET_SHARE_AUTOCOMPLETE, GET_SHARE_AUTOCOMPLETE),
  ])
}
