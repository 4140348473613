/* eslint-disable camelcase */
import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import store from 'store'
// import { history } from 'index'
import * as jwt from 'services/jwt'
import { history } from 'index'
import actions from './actions'

const jwts = {
  login: jwt.login,
  submitToken: jwt.submitToken,
  logout: jwt.logout,
  uploaEditUser: jwt.uploaEditUser,
}

export function* LOGIN({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.login, payload)
  if (success.status === true) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
        moda: true,
      },
    })
    notification.success({
      message: 'Success!',
      description: success.message,
    })
  }
  if (success.status === false) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.warning({
      message: 'Error!',
      description: success.message,
    })
  }
}

export function* SUBMIT_TOKEN({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.submitToken, payload)
  if (success.status) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        id:success?.data?.admin_id,
        name: success?.data?.name,
        email: success?.data?.email,
        role: success?.data?.role,
        authorized: true,
        loading: false,
        userLoginData: success?.data,
        moda: false,
        permissions: success?.data?.permissions
      },
    })
    yield history.push('/dashboard')
    store.set('adminData', success?.data)
    store.set('accessToken', success?.data?.token)
    store.set('adminPermissions', success?.data?.permissions)
    notification.success({
      message: 'Logged In',
      // description: success.message,
    })
  }
  if (!success.status) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Log In Error',
      description: success?.message,
    })
  }
}
export function* LOGOUT() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      id: '',
      name: '',
      email: '',
      avatar: '',
      authorized: false,
      userStatus: {},
      loading: false,
      moda: false,
    },
  })
  notification.success({
    message: 'Logged Out',
    description: 'hope to see you soon.',
  })
  store.clearAll()
}
export function* UPLOAD_EDIT_USER({ payload }) {
  console.log(payload)
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.uploaEditUser, payload)
  if (success.status) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.success({
      message: success.message,
    })
  }
  if (!success.status) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success.message,
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.SUBMIT_TOKEN, SUBMIT_TOKEN),
    takeEvery(actions.LOGOUT, LOGOUT),
    takeEvery(actions.UPLOAD_EDIT_USER, UPLOAD_EDIT_USER),
    // LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ])
}
