/* eslint-disable camelcase */
import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
// import store from 'store'
// import { history } from 'index'
import * as jwt from 'services/jwt'
import actions from './actions'

const jwts = {
  getBacklogCategory: jwt.getBacklogCategory,
  getBacklogTransaction: jwt.getBacklogTransaction,
}

export function* GET_BACKLOG_TRANSACTIONS({ payload }) {
  yield put({
    type: 'backlog/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getBacklogTransaction, payload)
  if (success.status) {
    yield put({
      type: 'backlog/SET_STATE',
      payload: {
        loading: false,
        backlogTransactions: success?.data || [],
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'backlog/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success.message,
    })
  }
}

export function* GET_BACKLOG_CATEGORY() {
  yield put({
    type: 'backlog/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getBacklogCategory)
  if (success.status) {
    yield put({
      type: 'backlog/SET_STATE',
      payload: {
        loading: false,
        backlogCategory: success?.data || success?.acct_types || [] ,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'backlog/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success.message,
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_BACKLOG_CATEGORY, GET_BACKLOG_CATEGORY),
    takeEvery(actions.GET_BACKLOG_TRANSACTIONS, GET_BACKLOG_TRANSACTIONS),
  ])
}
