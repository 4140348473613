import { all, takeEvery, put, call } from 'redux-saga/effects'
import store from 'store'
import qs from 'qs'
import { notification } from 'antd'
import { history, store as reduxStore } from 'index'
import * as jwt from 'services/jwt'
import actions from './actions'

const jwts = {
  getAllRoles: jwt.getAllRoles,
  getAllPermissions: jwt.getAllPermissions,
  getZeroStaffs: jwt.getZeroStaffs,
  createZeroRole: jwt.createZeroRole,
  deleteZeroRole: jwt.deleteZeroRole,
  addZeroStaff: jwt.addZeroStaff,
  getSingleStaffDetails: jwt.getSingleStaffDetails,
  updateSingleStaff: jwt.updateSingleStaff,
  enableDisableZeroStaff: jwt.enableDisableZeroStaff,
  getZeroSingleRole: jwt.getZeroSingleRole,
  updateZeroRole: jwt.updateZeroRole,
  submitProcurementDuration: jwt.submitProcurementDuration,
  displayProcurementDuration: jwt.displayProcurementDuration,
  submitLoanDuration: jwt.submitLoanDuration,
  displayLoanDuration: jwt.displayLoanDuration,
  getAllOrganizations: jwt.getAllOrganizations,
  createOrganizations: jwt.createOrganizations,
  deactivateOrganizations: jwt.deactivateOrganizations,
  createApiKey: jwt.createApiKey,
  getApiKeyList: jwt.getApiKeyList,
  toggleApiKey: jwt.toggleApiKey,
}

export function* CHANGE_SETTING({ payload: { setting, value } }) {
  yield store.set(`app.settings.${setting}`, value)
  yield put({
    type: 'settings/SET_STATE',
    payload: {
      [setting]: value,
    },
  })
}

export function* SET_PRIMARY_COLOR({ payload: { color } }) {
  const addStyles = () => {
    const styleElement = document.querySelector('#primaryColor')
    if (styleElement) {
      styleElement.remove()
    }
    const body = document.querySelector('body')
    const styleEl = document.createElement('style')
    const css = document.createTextNode(`:root { --kit-color-primary: ${color};}`)
    styleEl.setAttribute('id', 'primaryColor')
    styleEl.appendChild(css)
    body.appendChild(styleEl)
  }

  yield addStyles()
  yield reduxStore.dispatch({
    type: 'settings/CHANGE_SETTING',
    payload: {
      setting: 'primaryColor',
      value: color,
    },
  })
}

export function* SET_THEME({ payload: { theme } }) {
  const nextTheme = theme === 'dark' ? 'dark' : 'default'
  yield document.querySelector('html').setAttribute('data-kit-theme', nextTheme)
  yield reduxStore.dispatch({
    type: 'settings/CHANGE_SETTING',
    payload: {
      setting: 'theme',
      value: nextTheme,
    },
  })
}

export function* SETUP() {
  // load settings from url on app load
  const changeSettings = search => {
    const query = qs.parse(search, { ignoreQueryPrefix: true })
    Object.keys(query).forEach(key => {
      let value
      switch (query[key]) {
        case 'false':
          value = false
          break
        case 'true':
          value = true
          break
        default:
          value = query[key]
          break
      }
      if (key === 'theme') {
        reduxStore.dispatch({
          type: 'settings/SET_THEME',
          payload: {
            theme: value,
          },
        })
        return
      }
      reduxStore.dispatch({
        type: 'settings/CHANGE_SETTING',
        payload: {
          setting: key,
          value,
        },
      })
    })
  }
  yield changeSettings(history.location.search)
  yield history.listen(params => {
    const { search } = params
    changeSettings(search)
  })

  // set primary color on app load
  const primaryColor = () => {
    const color = store.get('app.settings.primaryColor')
    if (color) {
      reduxStore.dispatch({
        type: 'settings/SET_PRIMARY_COLOR',
        payload: {
          color,
        },
      })
    }
  }
  yield primaryColor()

  // init theme on app load
  const initTheme = () => {
    const { search } = history.location
    const query = qs.parse(search, { ignoreQueryPrefix: true })
    const theme = query.theme || store.get('app.settings.theme') || 'default'
    reduxStore.dispatch({
      type: 'settings/SET_THEME',
      payload: {
        theme,
      },
    })
  }
  yield initTheme()

  // detect isMobileView setting on app load and window resize
  const isMobileView = (load = false) => {
    const currentState = global.window.innerWidth < 768
    const prevState = store.get('app.settings.isMobileView')
    if (currentState !== prevState || load) {
      reduxStore.dispatch({
        type: 'settings/CHANGE_SETTING',
        payload: {
          setting: 'isMobileView',
          value: currentState,
        },
      })
    }
  }

  // detect viewport width on app load and window resize
  const isMenuToggled = () => {
    const shouldToggle = global.window.innerWidth < 1024
    const prevState = store.get('app.settings.isMenuCollapsed')
    if (shouldToggle || prevState) {
      reduxStore.dispatch({
        type: 'settings/CHANGE_SETTING',
        payload: {
          setting: 'isMenuCollapsed',
          value: true,
        },
      })
    }
  }

  yield isMobileView(true)
  yield isMenuToggled()
  yield window.addEventListener('resize', () => {
    isMobileView()
    isMenuToggled()
  })
}

export function* GET_ALL_ROLES({ payload }) {
  yield put({
    type: 'settings/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getAllRoles, payload)
  if (success.status) {
    yield put({
      type: 'settings/SET_STATE',
      payload: {
        loading: false,
        roleList: success?.data,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'settings/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success.message,
    })
  }
}

export function* GET_ALL_PERMISSIONS() {
  yield put({
    type: 'settings/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getAllPermissions)
  if (success.status) {
    yield put({
      type: 'settings/SET_STATE',
      payload: {
        loading: false,
        permissionList: success?.data,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'settings/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success.message,
    })
  }
}

export function* GET_ALL_ZERO_STAFFS({ payload }) {
  yield put({
    type: 'settings/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getZeroStaffs, payload)
  if (success.status) {
    yield put({
      type: 'settings/SET_STATE',
      payload: {
        loading: false,
        staffList: success?.data,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'settings/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success.message,
    })
  }
}

export function* CREATE_ZERO_ROLE({ payload }) {
  yield put({
    type: 'settings/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.createZeroRole, payload.payload)
  if (success.status) {
    yield put({
      type: 'settings/SET_STATE',
      payload: {
        loading: false,
      },
    })
    yield put({
      type: 'settings/GET_ALL_ROLES',
      payload: payload.rolesPayload,
    })
    notification.success({
      message: success.message,
    })
  }
  if (!success.status) {
    yield put({
      type: 'settings/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success.message,
    })
  }
}

export function* GET_SINGLE_ROLE_DETAILS({ payload }) {
  yield put({
    type: 'settings/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getZeroSingleRole, payload)
  if (success.status) {
    yield put({
      type: 'settings/SET_STATE',
      payload: {
        loading: false,
        roleDetail: success.data,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'settings/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success.message,
    })
  }
}

export function* UPDATE_SINGLE_ROLE_DETAILS({ payload }) {
  yield put({
    type: 'settings/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.updateZeroRole, payload.payload)
  if (success.status) {
    yield put({
      type: 'settings/SET_STATE',
      payload: {
        loading: false,
      },
    })
    yield put({
      type: 'settings/GET_ALL_ROLES',
      payload: payload.rolesPayload,
    })
    notification.success({
      message: success.message,
    })
  }
  if (!success.status) {
    yield put({
      type: 'settings/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success.message,
    })
  }
}

export function* DELETE_ZERO_ROLE({ payload }) {
  yield put({
    type: 'settings/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.deleteZeroRole, payload.payload)
  if (success.status) {
    yield put({
      type: 'settings/SET_STATE',
      payload: {
        loading: false,
      },
    })
    yield put({
      type: 'settings/GET_ALL_ROLES',
      payload: payload.rolesPayload,
    })
    notification.success({
      message: success.message,
    })
  }
  if (!success.status) {
    yield put({
      type: 'settings/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success.message,
    })
  }
}

export function* CREATE_ZERO_STAFF({ payload }) {
  yield put({
    type: 'settings/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.addZeroStaff, payload.payload)
  if (success.status) {
    yield put({
      type: 'settings/SET_STATE',
      payload: {
        loading: false,
      },
    })
    yield put({
      type: 'settings/GET_ALL_ZERO_STAFFS',
      payload: payload.staffPayload,
    })
    notification.success({
      message: success.message,
    })
  }
  if (!success.status) {
    yield put({
      type: 'settings/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success.message,
    })
  }
}

export function* GET_ZERO_STAFF_DETAILS({ payload }) {
  yield put({
    type: 'settings/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getSingleStaffDetails, payload)
  if (success.status) {
    yield put({
      type: 'settings/SET_STATE',
      payload: {
        loading: false,
        staffDetail: success.data,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'settings/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success.message,
    })
  }
}

export function* UPDATE_STAFF_DETAILS({ payload }) {
  yield put({
    type: 'settings/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.updateSingleStaff, payload.payload)
  if (success.status) {
    yield put({
      type: 'settings/SET_STATE',
      payload: {
        loading: false,
      },
    })
    yield put({
      type: 'settings/GET_ALL_ZERO_STAFFS',
      payload: payload.staffPayload,
    })
    notification.success({
      message: success.message,
    })
  }
  if (!success.status) {
    yield put({
      type: 'settings/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success.message,
    })
  }
}

export function* ENABLE_DISABLE_ZERO_STAFF({ payload }) {
  yield put({
    type: 'settings/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.enableDisableZeroStaff, payload.payload)
  if (success.status) {
    yield put({
      type: 'settings/SET_STATE',
      payload: {
        loading: false,
      },
    })
    yield put({
      type: 'settings/GET_ALL_ZERO_STAFFS',
      payload: payload.staffPayload,
    })
    notification.success({
      message: success.message,
    })
  }
  if (!success.status) {
    yield put({
      type: 'settings/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success.message,
    })
  }
}

export function* DISPLAY_LOAN_DURATION() {
  yield put({
    type: 'settings/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.displayLoanDuration)
  if (success.status) {
    yield put({
      type: 'settings/SET_STATE',
      payload: {
        loading: false,
        loanDurationTable: success?.data,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'settings/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success.message,
    })
  }
}

export function* SUBMIT_LOAN_DURATION({ payload }) {
  yield put({
    type: 'settings/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.submitLoanDuration, payload)
  if (success.status) {
    yield put({
      type: 'settings/SET_STATE',
      payload: {
        loading: false,
      },
    })
    yield put({ type: 'settings/DISPLAY_LOAN_DURATION' })
    notification.success({
      message: success.message,
    })
  }
  if (!success.status) {
    yield put({
      type: 'settings/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: success.message,
    })
  }
}

export function* DISPLAY_PROCUREMENT_DURATION() {
  yield put({
    type: 'settings/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.displayProcurementDuration)
  if (success.status) {
    yield put({
      type: 'settings/SET_STATE',
      payload: {
        loading: false,
        procurementDurationTable: success?.data,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'settings/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success.message,
    })
  }
}

export function* SUBMIT_PROCUREMENT_DURATION({ payload }) {
  yield put({
    type: 'settings/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.submitProcurementDuration, payload)
  if (success.status) {
    yield put({
      type: 'settings/SET_STATE',
      payload: {
        loading: false,
      },
    })
    yield put({ type: 'settings/DISPLAY_PROCUREMENT_DURATION' })
    notification.success({
      message: success.message,
    })
  }
  if (!success.status) {
    yield put({
      type: 'settings/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: success.message,
    })
  }
}

export function* GET_ALL_ORGANIZATIONS({payload}) {
  yield put({
    type: 'settings/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getAllOrganizations, payload)
  if (success.status) {
    yield put({
      type: 'settings/SET_STATE',
      payload: {
        loading: false,
        organizationList: success.data
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'settings/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: success.message,
    })
  }
}


export function* CREATE_ORGANIZATION({payload}) {
  yield put({
    type: 'settings/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.createOrganizations, payload.data)
  if (success.status) {
    yield put({
      type: 'settings/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.success({
      message: success.message,
    })
    yield put({type: "settings/GET_ALL_ORGANIZATIONS", payload: payload.payload})
  }
  if (!success.status) {
    yield put({
      type: 'settings/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: success.message,
    })
  }
}


export function* DEACTIVATE_ORGANIZATION({payload}) {
  yield put({
    type: 'settings/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.deactivateOrganizations, payload.data)
  if (success.status) {
    yield put({
      type: 'settings/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.success({
      message: success.message,
    })
    yield put({type: "settings/GET_ALL_ORGANIZATIONS", payload: payload.payload})
  }
  if (!success.status) {
    yield put({
      type: 'settings/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: success.message,
    })
  }
}


export function* GET_API_KEYS({payload}) {
  yield put({
    type: 'settings/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getApiKeyList, payload)
  if (success.status) {
    yield put({
      type: 'settings/SET_STATE',
      payload: {
        loading: false,
        apiKeyList: success.data
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'settings/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: success.message,
    })
  }
}

export function* CREATE_API_KEY({payload}) {
  yield put({
    type: 'settings/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.createApiKey, payload)
  if (success.status) {
    yield put({
      type: 'settings/SET_STATE',
      payload: {
        loading: false,
      },
    })
    yield put({type: 'settings/GET_API_KEYS'})
    notification.success({
      message: success.message,
    })
  }
  if (!success.status) {
    yield put({
      type: 'settings/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: success.message,
    })
  }
}

export function* TOGGLE_API_KEY({payload}) {
  yield put({
    type: 'settings/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.toggleApiKey, payload?.data)
  if (success.status) {
    yield put({
      type: 'settings/SET_STATE',
      payload: {
        loading: false,
      },
    })
    yield put({type: 'settings/GET_API_KEYS', payload: payload?.payload})
    notification.success({
      message: success.message,
    })
  }
  if (!success.status) {
    yield put({
      type: 'settings/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: success.message,
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CHANGE_SETTING, CHANGE_SETTING),
    takeEvery(actions.SET_PRIMARY_COLOR, SET_PRIMARY_COLOR),
    takeEvery(actions.SET_THEME, SET_THEME),
    takeEvery(actions.GET_ALL_ROLES, GET_ALL_ROLES),
    takeEvery(actions.GET_ALL_PERMISSIONS, GET_ALL_PERMISSIONS),
    takeEvery(actions.GET_ALL_ZERO_STAFFS, GET_ALL_ZERO_STAFFS),
    takeEvery(actions.CREATE_ZERO_ROLE, CREATE_ZERO_ROLE),
    takeEvery(actions.DELETE_ZERO_ROLE, DELETE_ZERO_ROLE),
    takeEvery(actions.CREATE_ZERO_STAFF, CREATE_ZERO_STAFF),
    takeEvery(actions.GET_ZERO_STAFF_DETAILS, GET_ZERO_STAFF_DETAILS),
    takeEvery(actions.UPDATE_STAFF_DETAILS, UPDATE_STAFF_DETAILS),
    takeEvery(actions.ENABLE_DISABLE_ZERO_STAFF, ENABLE_DISABLE_ZERO_STAFF),
    takeEvery(actions.GET_SINGLE_ROLE_DETAILS, GET_SINGLE_ROLE_DETAILS),
    takeEvery(actions.UPDATE_SINGLE_ROLE_DETAILS, UPDATE_SINGLE_ROLE_DETAILS),
    takeEvery(actions.SUBMIT_LOAN_DURATION, SUBMIT_LOAN_DURATION),
    takeEvery(actions.DISPLAY_LOAN_DURATION, DISPLAY_LOAN_DURATION),
    takeEvery(actions.SUBMIT_PROCUREMENT_DURATION, SUBMIT_PROCUREMENT_DURATION),
    takeEvery(actions.DISPLAY_PROCUREMENT_DURATION, DISPLAY_PROCUREMENT_DURATION),
    takeEvery(actions.GET_ALL_ORGANIZATIONS, GET_ALL_ORGANIZATIONS),
    takeEvery(actions.CREATE_ORGANIZATION, CREATE_ORGANIZATION),
    takeEvery(actions.DEACTIVATE_ORGANIZATION, DEACTIVATE_ORGANIZATION),
    takeEvery(actions.GET_API_KEYS, GET_API_KEYS),
    takeEvery(actions.CREATE_API_KEY, CREATE_API_KEY),
    takeEvery(actions.TOGGLE_API_KEY, TOGGLE_API_KEY),
    SETUP(), // run once on app load to init listeners
  ])
}
