import actions from './actions'

const initialState = {
  loading: false,
  tabKey: '1',
  procurementSummary: {},
  pendingProcurementRequest: [],
  allProcureRequest: [],
  approvedProcurementRequest: [],
  processingProcurementRequest: [],
  disbursedProcurementRequest: [],
  ongoingProcurementRequest: [],
  completedProcurementRequest: [],
  cancelledProcurementRequest: [],
  procurementDetail: {},
  procurementTransDetail: {},
  procurementTransTransaction: [],
  procureRepays:[],
  payDetails:[],
  feeData:[],
  amountRepaid: 0,
  loanAmount: 0,
  credit: 0,
  debit: 0,
  filterBalance: 0,
  procurementAdmin: [],
  uploadHistory:[],
  procurementGuarantor: [],
  procurementDetailStatus: ""
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
