const actions = {
  SET_STATE: 'account/SET_STATE',
  SET_TAB_KEY: 'account/SET_TAB_KEY',
  GET_ZERO_ACCOUNT_SUMMARY: 'account/GET_ZERO_ACCOUNT_SUMMARY',
  GET_ZERO_PENDING_ACCOUNT: 'account/GET_ZERO_PENDING_ACCOUNT',
  GET_ZERO_APPROVED_ACCOUNT: 'account/GET_ZERO_APPROVED_ACCOUNT',
  GET_ZERO_CANCELLED_ACCOUNT: 'account/GET_ZERO_CANCELLED_ACCOUNT',
  GET_ZERO_ACCOUNT_DETAILS: 'account/GET_ZERO_ACCOUNT_DETAILS',
  APPROVED_DECLINE_ZERO_ACCOUNT: 'account/APPROVED_DECLINE_ZERO_ACCOUNT',
}

export default actions
