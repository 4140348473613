const actions = {
  SET_STATE: 'transactions/SET_STATE',
  GET_TRANSACTION_CATEGORY: 'transactions/GET_TRANSACTION_CATEGORY',
  GET_USER_TRANSACTIONS: 'transactions/GET_USER_TRANSACTIONS',
  CHECK_EXCEL_DATA: 'transactions/CHECK_EXCEL_DATA',
  SEND_EXCEL_DATA: 'transactions/SEND_EXCEL_DATA',
  REVERSE_UPLOAD: 'transactions/REVERSE_UPLOAD',
  REVERSE_EXCEL_DATA: 'transactions/REVERSE_EXCEL_DATA',
  GET_DOUBLE_EXCEL_DETAIL: 'transactions/GET_DOUBLE_EXCEL_DETAIL',
  GET_EXCEL_HISTORY: 'transactions/GET_EXCEL_HISTORY',
  M_REUPLOAD_DEDUCTION: 'transactions/M_REUPLOAD_DEDUCTION',
  REUPLOAD_DEDUCTION: 'transactions/REUPLOAD_DEDUCTION',
  APPROVE_REUPLOAD: 'transactions/APPROVE_REUPLOAD',
  GET_UPLOAD_TRANSACTIONS_HISTORY: 'transactions/GET_UPLOAD_TRANSACTIONS_HISTORY',
}

export default actions
