import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
// import store from 'store'
// import { history } from 'index'
import * as jwt from 'services/jwt'
import actions from './actions'

const jwts = {
  getTransactionCategory: jwt.getTransactionCategory,
  getUserTransactions: jwt.getUserTransactions,
  checkUploadedExcelData: jwt.checkUploadedExcelData,
  reverseUploadedExcelData: jwt.reverseUploadedExcelData,
  uploadReverse: jwt.uploadReverse,
  sendUploadedExcelData: jwt.sendUploadedExcelData,
  getUloadTransactions: jwt.getUloadTransactions, 
  getExcelHistory: jwt.getExcelHistory,
  approveDeclineReupload: jwt.approveDeclineReupload,
  reuploadDeduction: jwt.reuploadDeduction,
  mulpleReuploadDed: jwt.mulpleReuploadDed,
  getDoubleExcelDetail: jwt.getDoubleExcelDetail,
}

export function* GET_USER_TRANSACTIONS({ payload }) {
  yield put({
    type: 'transactions/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getUserTransactions, payload)
  if (success.status) {
    yield put({
      type: 'transactions/SET_STATE',
      payload: {
        loading: false,
        userTransactions: success?.data,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'transactions/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success.message,
    })
  }
}

export function* GET_TRANSACTION_CATEGORY() {
  yield put({
    type: 'transactions/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getTransactionCategory)
  if (success.status) {
    yield put({
      type: 'transactions/SET_STATE',
      payload: {
        loading: false,
        transactionCategory: success?.data,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'transactions/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success.message,
    })
  }
}

export function* CHECK_EXCEL_DATA({ payload }) {
  yield put({
    type: 'transactions/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.checkUploadedExcelData, payload)
  if (success.status) {
    yield put({
      type: 'transactions/SET_STATE',
      payload: {
        loading: false,
        checkedExcelData: success?.data,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'transactions/SET_STATE',
      payload: {
        loading: false,
        checkedExcelData: [],
      },
    })
    notification.error({
      message: 'Error!',
      description: success.message,
    })
  }
}

export function* REVERSE_EXCEL_DATA({ payload }) {
  yield put({
    type: 'transactions/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.reverseUploadedExcelData, payload)
  if (success.status) {
    yield put({
      type: 'transactions/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.success({
      message: 'Success!',
      description: success.message,
    })
    yield put({
      type: 'transactions/GET_UPLOAD_TRANSACTIONS_HISTORY'
    })
  }
  if (!success.status) {
    yield put({
      type: 'transactions/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success.message,
    })
  }
}

export function* SEND_EXCEL_DATA({ payload }) {
  console.log(payload)
  yield put({
    type: 'transactions/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.sendUploadedExcelData, payload)
  if (success.status) {
    yield put({
      type: 'transactions/SET_STATE',
      payload: {
        loading: false,
        uploadedData: success?.success,
        checkedExcelData: [],
      },
    })
    notification.success({
      message: 'Success!',
      description: success.message,
    })
    yield put({
      type: 'transactions/GET_UPLOAD_TRANSACTIONS_HISTORY'
    })
  }
  if (!success.status) {
    yield put({
      type: 'transactions/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success.message,
    })
  }
}

export function* REVERSE_UPLOAD({ payload }) {
  console.log(payload)
  yield put({
    type: 'transactions/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.uploadReverse, payload)
  if (success.status) {
    yield put({
      type: 'transactions/SET_STATE',
      payload: {
        loading: false,
        uploadedData: success?.success,
        checkedExcelData: [],
      },
    })
    notification.success({
      message: 'Success!',
      description: success.message,
    })
    yield put({
      type: 'transactions/GET_UPLOAD_TRANSACTIONS_HISTORY'
    })
  }
  if (!success.status) {
    yield put({
      type: 'transactions/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success.message,
    })
  }
}

export function* GET_UPLOAD_TRANSACTIONS_HISTORY({ payload }) {
  yield put({
    type: 'transactions/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getUloadTransactions, payload)
  if (success.status) {
    yield put({
      type: 'transactions/SET_STATE',
      payload: {
        loading: false,
        uploadHistory: success?.data?.data,
        doubleHistory: success?.data?.double,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'transactions/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success.message,
    })
  }
}

export function* GET_EXCEL_HISTORY({ payload }) {
  yield put({
    type: 'transactions/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getExcelHistory, payload)
  if (success?.data?.status) {
    yield put({
      type: 'transactions/SET_STATE',
      payload: {
        loading: false,
        uploadHistory: success?.data?.data,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'transactions/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success.message,
    })
  }
}

export function* GET_DOUBLE_EXCEL_DETAIL({ payload }) {
  yield put({
    type: 'transactions/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getDoubleExcelDetail, payload)
  if (success.status) {
    yield put({
      type: 'transactions/SET_STATE',
      payload: {
        loading: false,
        excelDetail: success?.data?.closure_details,
        excelAdmin: success?.data?.admins,
        excelDetailStatus: success?.data?.closure_status,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'transactions/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
    })
  }
}

export function* APPROVE_REUPLOAD({ payload }) {
  yield put({
    type: 'transactions/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.approveDeclineReupload, payload)
  if (success.status) {
    yield put({
      type: 'transactions/SET_STATE',
      payload: {
        loading: false,
      },
    })
    yield put({ type: 'transactions/GET_UPLOAD_TRANSACTIONS_HISTORY'  })
    yield put({ type: 'transactions/GET_DOUBLE_EXCEL_DETAIL', payload: payload?.excel_id })
    notification.success({
      message: success?.message || "Success",
    })
  }
  if (!success.status) {
    yield put({
      type: 'transactions/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success?.message,
    })
  }
}

export function* REUPLOAD_DEDUCTION({ payload }) {
  yield put({
    type: 'transactions/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.reuploadDeduction, payload)
  if (success.status) {
    yield put({
      type: 'transactions/SET_STATE',
      payload: {
        loading: false,
      },
    })
    yield put({ type: 'transactions/GET_UPLOAD_TRANSACTIONS_HISTORY'  })
    yield put({ type: 'transactions/GET_DOUBLE_EXCEL_DETAIL', payload: payload?.excel_id })
    notification.success({
      message: success?.message || "Success",
    })
  }
  if (!success.status) {
    yield put({
      type: 'transactions/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success?.message,
    })
  }
}

export function* M_REUPLOAD_DEDUCTION({ payload }) {
  yield put({
    type: 'transactions/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.mulpleReuploadDed, payload)
  if (success.status) {
    yield put({
      type: 'transactions/SET_STATE',
      payload: {
        loading: false,
      },
    })
    yield put({ type: 'transactions/GET_UPLOAD_TRANSACTIONS_HISTORY'  })
    yield put({ type: 'transactions/GET_DOUBLE_EXCEL_DETAIL', payload: payload?.excel_id })
    notification.success({
      message: success?.message || "Success",
    })
  }
  if (!success.status) {
    yield put({
      type: 'transactions/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success?.message,
    })
  }
}
export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_TRANSACTION_CATEGORY, GET_TRANSACTION_CATEGORY),
    takeEvery(actions.GET_USER_TRANSACTIONS, GET_USER_TRANSACTIONS),
    takeEvery(actions.CHECK_EXCEL_DATA, CHECK_EXCEL_DATA),
    takeEvery(actions.SEND_EXCEL_DATA, SEND_EXCEL_DATA),
    takeEvery(actions.REVERSE_UPLOAD, REVERSE_UPLOAD),
    takeEvery(actions.REVERSE_EXCEL_DATA, REVERSE_EXCEL_DATA),
    takeEvery(actions.REUPLOAD_DEDUCTION, REUPLOAD_DEDUCTION),
    takeEvery(actions.M_REUPLOAD_DEDUCTION, M_REUPLOAD_DEDUCTION),
    takeEvery(actions.APPROVE_REUPLOAD, APPROVE_REUPLOAD),
    takeEvery(actions.GET_EXCEL_HISTORY, GET_EXCEL_HISTORY),
    takeEvery(actions.GET_DOUBLE_EXCEL_DETAIL, GET_DOUBLE_EXCEL_DETAIL),
    takeEvery(actions.GET_UPLOAD_TRANSACTIONS_HISTORY, GET_UPLOAD_TRANSACTIONS_HISTORY),
  ])
}
