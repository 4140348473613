/* eslint-disable camelcase */
import { notification } from 'antd'
import { all, takeEvery, put, call } from 'redux-saga/effects'
import * as jwt from 'services/jwt'
import actions from './actions'

const jwts = {
  getZeroPendingRefundSummary: jwt.getZeroPendingRefundSummary,
  getZeroClosure: jwt.getZeroClosure,
  getZeroApprovedRefundSummary: jwt.getZeroApprovedRefundSummary,
  getZeroClaimedRefundSummary: jwt.getZeroClaimedRefundSummary,
  getZeroCancelledRefundSummary: jwt.getZeroCancelledRefundSummary,
  getZeroProcessingRefundSummary: jwt.getZeroProcessingRefundSummary,
  getZeroDisbursedRefundSummary: jwt.getZeroDisbursedRefundSummary,
  uploadRefundRequest: jwt.uploadRefundRequest,
  getClosureRequestDetail: jwt.getClosureRequestDetail,
  getZeroRefundRequestDetail: jwt.getZeroRefundRequestDetail,
  approveDeclineRefund: jwt.approveDeclineRefund,
  processRefundRequest: jwt.processRefundRequest,
  processClosure: jwt.processClosure,
  editClosureFee: jwt.editClosureFee,
  approveDeclineClosure: jwt.approveDeclineClosure,
  closeRefundRequest: ()=>{},
  // members/process_closure
}

export function* GET_ZERO_PENDING_REFUND({payload}) {
  console.log(payload)
  yield put({
    type: 'refund/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getZeroPendingRefundSummary, payload)
  if (success.status) {
    yield put({
      type: 'refund/SET_STATE',
      payload: {
        loading: false,
        pendingRefundRequest: success?.data?.pending,
        refundSummary: success?.data?.summary
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'refund/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
    })
  }
}

export function* GET_ZERO_APPROVED_REFUND() {
  yield put({
    type: 'refund/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getZeroApprovedRefundSummary)
  if (success.status) {
    yield put({
      type: 'refund/SET_STATE',
      payload: {
        loading: false,
        approvedRefundRequest: success.data?.approved,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'refund/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
    })
  }
}

export function* GET_ZERO_CLAIMED_REFUND({payload}) {
  yield put({
    type: 'refund/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getZeroClaimedRefundSummary, payload)
  if (success.status) {
    yield put({
      type: 'refund/SET_STATE',
      payload: {
        loading: false,
        claimedRefundRequest: success.data?.claimed,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'refund/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
    })
  }
}

export function* GET_ZERO_CANCELLED_REFUND({payload}) {
  yield put({
    type: 'refund/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getZeroCancelledRefundSummary, payload)
  if (success.status) {
    yield put({
      type: 'refund/SET_STATE',
      payload: {
        loading: false,
        cancelledRefundRequest: success.data?.cancelled,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'refund/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
    })
  }
}

export function* GET_ZERO_PROCESSING_REFUND({payload}) {
  yield put({
    type: 'refund/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getZeroProcessingRefundSummary, payload)
  if (success.status) {
    yield put({
      type: 'refund/SET_STATE',
      payload: {
        loading: false,
        processingRefundRequest: success.data?.processing,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'refund/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
    })
  }
}

export function* GET_ZERO_DISBURSED_REFUND({payload}) {
  yield put({
    type: 'refund/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getZeroDisbursedRefundSummary, payload)
  if (success.status) {
    yield put({
      type: 'refund/SET_STATE',
      payload: {
        loading: false,
        disbursedRefundRequest: success.data?.disbursed,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'refund/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
    })
  }
}

export function* GET_ZERO_REFUND_REQUEST() {
  yield put({
    type: 'refund/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getZeroRefundRequests)
  if (success.status) {
    yield put({
      type: 'refund/SET_STATE',
      payload: {
        loading: false,
        pendingRefundRequest: success?.data?.pending,
        markedRefundRequest: success?.data?.marked,
        approvedRefundRequest: success?.data?.approved,
        processingRefundRequest: success?.data?.processing,
        disbursedRefundRequest: success?.data?.disbursed,
        completedRefundRequest: success?.data?.completed,
        cancelledRefundRequest: success?.data?.cancelled,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'refund/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
    })
  }
}

export function* GET_ZERO_REFUND_DETAIL({ payload }) {
  yield put({
    type: 'refund/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getZeroRefundRequestDetail, payload)
  if (success.status) {
    yield put({
      type: 'refund/SET_STATE',
      payload: {
        loading: false,
        refundDetail: success?.data?.refund_details,
        refundAdmin: success?.data?.admins,
        refundGuarantor: success?.data?.guarantors,
        refundDetailStatus: success?.data?.refund_status,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'refund/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
    })
  }
}

export function* APPROVE_DECLINE_REFUND({ payload }) {
  yield put({
    type: 'refund/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.approveDeclineRefund, payload)
  if (success.status) {
    yield put({
      type: 'refund/SET_STATE',
      payload: {
        loading: false,
      },
    })
    yield put({ type: 'refund/GET_ZERO_CLAIMED_REFUND' })
    yield put({ type: 'refund/GET_ZERO_PENDING_REFUND' })
    yield put({ type: 'refund/GET_ZERO_PROCESSING_REFUND' })
    yield put({ type: 'refund/GET_ZERO_DISBURSED_REFUND' })
    yield put({ type: 'refund/GET_ZERO_REFUND_DETAIL', payload: payload?.refund_id })
    notification.success({
      message: success.message,
    })
  }
  if (!success.status) {
    yield put({
      type: 'refund/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success.message,
    })
  }
}

export function* PROCESS_REFUND({ payload }) {
  yield put({
    type: 'refund/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.processRefundRequest, payload)
  if (success.status) {
    yield put({
      type: 'refund/SET_STATE',
      payload: {
        loading: false,
      },
    })
    yield put({ type: 'refund/GET_ZERO_CLAIMED_REFUND' })
    yield put({ type: 'refund/GET_ZERO_PENDING_REFUND' })
    yield put({ type: 'refund/GET_ZERO_PROCESSING_REFUND' })
    yield put({ type: 'refund/GET_ZERO_DISBURSED_REFUND' })
    notification.success({
      message: success.message,
    })
  }
  if (!success.status) {
    yield put({
      type: 'refund/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success.message,
    })
  }
}

export function* UPLOAD_REFUND({ payload }) {
  console.log(payload)
  yield put({
    type: 'refund/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.uploadRefundRequest, payload)
  if (success.status) {
    yield put({
      type: 'refund/SET_STATE',
      payload: {
        loading: false,
      },
    })
    yield put({ type: 'refund/GET_ZERO_PENDING_REFUND' })
    yield put({ type: 'refund/GET_ZERO_CANCELLED_REFUND' })
    yield put({ type: 'refund/GET_ZERO_PROCESSING_REFUND' })
    yield put({ type: 'refund/GET_ZERO_DISBURSED_REFUND' })
    notification.success({
      message: success.message,
    })
  }
  if (!success.status) {
    yield put({
      type: 'refund/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success.message,
    })
  }
}

export function* CLOSE_REFUND({ payload }) {
  yield put({
    type: 'refund/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.closeRefundRequest, payload)
  if (success.status) {
    yield put({
      type: 'refund/SET_STATE',
      payload: {
        loading: false,
      },
    })
    yield put({ type: 'refund/GET_ZERO_REFUND_SUMMARY' })
    yield put({ type: 'refund/GET_ZERO_REFUND_REQUEST' })
    notification.success({
      message: success.message,
    })
  }
  if (!success.status) {
    yield put({
      type: 'refund/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success.message,
    })
  }
}

export function* GET_ZERO_CLOSURE({payload}) {
  // console.log(payload)
  yield put({
    type: 'refund/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getZeroClosure, payload)
  // console.log(success)
  // console.log(success)
  if (success.status) {
    yield put({
      type: 'refund/SET_STATE',
      payload: {
        loading: false,
        pendingClosure: success?.pending,
        processingClosure: success?.processing,
        disbursedClosure: success?.disbursed,
        cancelledClosure: success?.cancelled,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'refund/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
    })
  }
}

export function* GET_CLOSURE_DETAIL({ payload }) {
  yield put({
    type: 'refund/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getClosureRequestDetail, payload)
  if (success.status) {
    yield put({
      type: 'refund/SET_STATE',
      payload: {
        loading: false,
        closureDetail: success?.data?.closure_details,
        closureAdmin: success?.data?.admins,
        // refundGuarantor: success?.data?.guarantors,
        closureDetailStatus: success?.data?.closure_status,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'refund/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
    })
  }
}

export function* APPROVE_CLOSURE({ payload, data }) {
  yield put({
    type: 'refund/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.approveDeclineClosure, payload)
  if (success.status) {
    yield put({
      type: 'refund/SET_STATE',
      payload: {
        loading: false,
      },
    })
    yield put({ type: 'refund/GET_ZERO_CLOSURE', payload: data  })
    yield put({ type: 'refund/GET_CLOSURE_DETAIL', payload: payload?.closure_id })
    notification.success({
      message: success?.message || "Success",
    })
  }
  if (!success.status) {
    yield put({
      type: 'refund/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success?.message,
    })
  }
}

export function* PROCESS_CLOSURE({ payload, data }) {
  yield put({
    type: 'refund/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.processClosure, payload)
  if (success.status) {
    yield put({
      type: 'refund/SET_STATE',
      payload: {
        loading: false,
      },
    })
    yield put({ type: 'refund/GET_ZERO_CLOSURE', payload: data  })
    yield put({ type: 'refund/GET_CLOSURE_DETAIL', payload: payload?.get_selection?.[0]})
    notification.success({
      message: success.message,
    })
  }
  if (!success.status) {
    yield put({
      type: 'refund/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success.message,
    })
  }
}


export function* EDIT_CLOSURE_FEE({ payload }) {
  yield put({
    type: 'refund/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.editClosureFee, payload?.data)
  if (success.status) {
    yield put({
      type: 'refund/SET_STATE',
      payload: {
        loading: false,
      },
    })
    yield put({ type: 'refund/GET_ZERO_CLOSURE', payload: payload?.param  })
    yield put({ type: 'refund/GET_CLOSURE_DETAIL', payload: payload?.data?.id })
    notification.success({
      message: success.message || "Success",
    })
  }
  if (!success.status) {
    yield put({
      type: 'refund/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success.message,
    })
  }
}

export function* SET_TAB_KEY({ payload }) {
  yield put({
    type: 'refund/SET_STATE',
    payload: {
      tabKey: payload,
    },
  })
}

export default function* rootSaga() {
  yield all([takeEvery(actions.SET_TAB_KEY, SET_TAB_KEY)])
  yield all([takeEvery(actions.GET_ZERO_PENDING_REFUND, GET_ZERO_PENDING_REFUND)])
  yield all([takeEvery(actions.GET_ZERO_CLOSURE, GET_ZERO_CLOSURE)])
  yield all([takeEvery(actions.GET_ZERO_APPROVED_REFUND, GET_ZERO_APPROVED_REFUND)])
  yield all([takeEvery(actions.GET_ZERO_CLAIMED_REFUND, GET_ZERO_CLAIMED_REFUND)])
  yield all([takeEvery(actions.GET_ZERO_CANCELLED_REFUND, GET_ZERO_CANCELLED_REFUND)])
  yield all([takeEvery(actions.GET_ZERO_PROCESSING_REFUND, GET_ZERO_PROCESSING_REFUND)])
  yield all([takeEvery(actions.GET_ZERO_DISBURSED_REFUND, GET_ZERO_DISBURSED_REFUND)])
  yield all([takeEvery(actions.GET_ZERO_REFUND_REQUEST, GET_ZERO_REFUND_REQUEST)])
  yield all([takeEvery(actions.GET_ZERO_REFUND_DETAIL, GET_ZERO_REFUND_DETAIL)])
  yield all([takeEvery(actions.APPROVE_DECLINE_REFUND, APPROVE_DECLINE_REFUND)])
  yield all([takeEvery(actions.PROCESS_REFUND, PROCESS_REFUND)])
  yield all([takeEvery(actions.UPLOAD_REFUND, UPLOAD_REFUND)])
  yield all([takeEvery(actions.CLOSE_REFUND, CLOSE_REFUND)])
  yield all([takeEvery(actions.GET_CLOSURE_DETAIL, GET_CLOSURE_DETAIL)])
  yield all([takeEvery(actions.PROCESS_CLOSURE, PROCESS_CLOSURE)])
  yield all([takeEvery(actions.APPROVE_CLOSURE, APPROVE_CLOSURE)])
  yield all([takeEvery(actions.EDIT_CLOSURE_FEE, EDIT_CLOSURE_FEE)])
}
