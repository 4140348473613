import actions from './actions'

const initialState = {
  loading: false,
  tabKey: '1',
  refundSummary: {},
  pendingRefundRequest: [],
  markedRefundRequest: [],
  approvedRefundRequest: [],
  processingRefundRequest: [],
  disbursedRefundRequest: [],
  claimedRefundRequest: [],
  cancelledRefundRequest: [],
  pendingClosure:[],
  processingClosure:[],
  disbursedClosure:[],
  cancelledClosure:[],
  closureDetail:[],
  closureDetailStatus:"",
  refundDetail: {},
  refundAdmin: [],
  refundGuarantor: [],
  refundDetailStatus: ""
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
