const actions = {
  SET_STATE: 'member/SET_STATE',
  GET_ZERO_MEMBER: 'member/GET_ZERO_MEMBER',
  GET_ZERO_INACTIVE_MEMBER: 'member/GET_ZERO_INACTIVE_MEMBER',
  GET_ZERO_PROCURE_MEMBER: 'member/GET_ZERO_PROCURE_MEMBER',
  GET_ZERO_INCOMPLETE_MEMBER: 'member/GET_ZERO_INCOMPLETE_MEMBER',
  ADD_ZERO_MEMBER: 'member/ADD_ZERO_MEMBER',
  SET_TAB_KEY: 'member/SET_TAB_KEY',
  IMPORT_ZERO_MEMBER: 'member/IMPORT_ZERO_MEMBER',
  GET_ZERO_MEMBER_GUARANTEE_LOAN: 'member/GET_ZERO_MEMBER_GUARANTEE_LOAN',
  ENABLE_DISABLE_ZERO_MEMBER: 'member/ENABLE_DISABLE_ZERO_MEMBER',
  RESEND_EMAIL_LINK: 'member/RESEND_EMAIL_LINK',
  PROCURE_LOAN_ACK: 'member/PROCURE_LOAN_ACK',
  GET_ZERO_MEMBER_DETAILS: 'member/GET_ZERO_MEMBER_DETAILS',
  GET_ZERO_MEMBER_SAVING_DETAIL: 'member/GET_ZERO_MEMBER_SAVING_DETAIL',
  GET_ZERO_MEMBER_SAVING_TRANS: 'member/GET_ZERO_MEMBER_SAVING_TRANS',
  GET_ZERO_MEMBER_TARGET_DETAIL: 'member/GET_ZERO_MEMBER_TARGET_DETAIL',
  GET_ZERO_MEMBER_TARGET_TRANS: 'member/GET_ZERO_MEMBER_TARGET_TRANS',
  GET_ZERO_MEMBER_SHARE_DETAIL: 'member/GET_ZERO_MEMBER_SHARE_DETAIL',
  GET_ZERO_MEMBER_SHARE_TRANS: 'member/GET_ZERO_MEMBER_SHARE_TRANS',
  GET_ZERO_MEMBER_LOAN_DETAIL: 'member/GET_ZERO_MEMBER_LOAN_DETAIL',
  GET_ZERO_MEMBER_LOAN_TRANS: 'member/GET_ZERO_MEMBER_LOAN_TRANS',
  GET_ZERO_MEMBER_PROCUREMENT_DETAIL: 'member/GET_ZERO_MEMBER_PROCUREMENT_DETAIL',
  GET_ZERO_MEMBER_PROCUREMENT_TRANS: 'member/GET_ZERO_MEMBER_PROCUREMENT_TRANS',
  GET_ZERO_MEMBER_WALLET_TRANS: 'member/GET_ZERO_MEMBER_WALLET_TRANS',
  GET_ZERO_MEMBER_WITHDRAWAL_WAIVER: 'member/GET_ZERO_MEMBER_WITHDRAWAL_WAIVER',
  SET_ZERO_MEMBER_WITHDRAWAL_WAIVER: 'member/SET_ZERO_MEMBER_WITHDRAWAL_WAIVER',
  SET_ZERO_MEMBER_LOAN_WAIVER: 'member/SET_ZERO_MEMBER_LOAN_WAIVER',
  EDIT_MEMBER_TYPE: 'member/EDIT_MEMBER_TYPE',
  SET_ZERO_MEMBER_DETAIL: 'member/SET_ZERO_MEMBER_DETAIL',
  SET_ZERO_MEMBER_STATUS: 'member/SET_ZERO_MEMBER_STATUS',
  INTER_SAVINGS_TRANSFER: 'member/INTER_SAVINGS_TRANSFER',
  GET_ZERO_MEMBER_PACKAGE_DEBT: 'member/GET_ZERO_MEMBER_PACKAGE_DEBT',
  MOVE_ZERO_MEMBER_PACKAGE_DEBT: 'member/MOVE_ZERO_MEMBER_PACKAGE_DEBT',
  SET_ZERO_MEMBER_PROCUREMENT_WAIVER: 'member/SET_ZERO_MEMBER_PROCUREMENT_WAIVER',
}

export default actions
