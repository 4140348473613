import { all } from 'redux-saga/effects'
import user from './user/sagas'
import menu from './menu/sagas'
import savings from './savings/sagas'
import settings from './settings/sagas'
import loan from './loan/sagas'
import activity from './activity/sagas'
import member from './member/sagas'
import account from './account/sagas'
import transactions from './transactions/sagas'
import dashboard from './dashboard/sagas'
import withdrawals from './withdrawal/sagas'
import target from './target/sagas'
import share from './share/sagas'
import procurement from './procurement/sagas'
import posting from './posting/sagas'
import backlog from './backlog/sagas'
import refund from './refund/sagas'
import reports from './reports/sagas'
import techendpay from './techendpay/sagas'
import wootransactions from './wootransactions/sagas'

export default function* rootSaga() {
  yield all([
    user(),
    menu(),
    savings(),
    settings(),
    loan(),
    member(),
    account(),
    activity(),
    dashboard(),
    transactions(),
    withdrawals(),
    target(),
    share(),
    procurement(),
    posting(),
    backlog(),
    refund(),
    reports(),
    techendpay(),
    wootransactions(),
  ])
}
