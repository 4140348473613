/* eslint-disable camelcase */
import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification, message} from 'antd'
// import store from 'store'
// import { history } from 'index'
import * as jwt from 'services/jwt'
import actions from './actions'

const jwts = {
  getPostingAutocomplete: jwt.getPostingAutocomplete,
  getPackageCategory: jwt.getPackageCategory,
  getGlAccountCode: jwt.getGlAccountCode,
  displayPostingTable: jwt.displayPostingTable,
  submitPostingReversal: jwt.submitPostingReversal,
  submitPostingRecords: jwt.submitPostingRecords,
  getTargetList: jwt.getTargetList,
}

export function* DISPLAY_POSTING_TABLE({ payload }) {
    yield put({
      type: 'posting/SET_STATE',
      payload: {
        loading: true,
      },
    })
    const success = yield call(jwts.displayPostingTable, payload)
    if (success.status) {
      yield put({
        type: 'posting/SET_STATE',
        payload: {
          loading: false,
          postingTable: success?.data,
        },
      })
    }
    if (!success.status) {
      yield put({
        type: 'posting/SET_STATE',
        payload: {
          loading: false,
        },
      })
      notification.error({
        message: 'Error!',
        description: success.message,
      })
    }
  }

  export function* SUBMIT_POSTING_RECORDS({payload}) {
    yield put({
      type: 'posting/SET_STATE',
      payload: {
        loading: true,
      },
    })
    const success = yield call(jwts.submitPostingRecords, payload)
    if (success.status) {
      yield put({
        type: 'posting/SET_STATE',
        payload: {
          loading: false,
        }
      })
      yield put({type: 'posting/GET_POSTING_AUTOCOMPLETE'})
      yield put({type: 'posting/GET_PACKAGE_CATEGORY'})
      yield put({type: 'posting/DISPLAY_POSTING_TABLE'})
      notification.success({
        message: success.message,
      })
    }
    if (!success.status) {
      yield put({
        type: 'posting/SET_STATE',
        payload: {
          loading: false,
        },
      })
      notification.error({
        message: success.message,
      })
    }
  }
  export function* REVERSAL({payload}) {
    // console.log(payload)
    yield put({
      type: 'posting/SET_STATE',
      payload: {
        loading: true,
      },
    })
    const success = yield call(jwts.submitPostingReversal, payload)
    if (success.status) {
      yield put({
        type: 'posting/SET_STATE',
        payload: {
          loading: false,
        }
      })
      yield put({type: 'posting/GET_POSTING_AUTOCOMPLETE'})
      yield put({type: 'posting/GET_PACKAGE_CATEGORY'})
      yield put({type: 'posting/DISPLAY_POSTING_TABLE'})
      notification.success({
        message: success.message,
      })
    }
    if (!success.status) {
      yield put({
        type: 'posting/SET_STATE',
        payload: {
          loading: false,
        },
      })
      notification.error({
        message: success.message,
      })
    }
  }

export function* GET_POSTING_AUTOCOMPLETE({ payload }) {
  yield put({
    type: 'posting/SET_STATE',
    payload: {
      loading: false,
    },
  })
  const success = yield call(jwts.getPostingAutocomplete, payload)
  if (success.status) {
    yield put({
      type: 'posting/SET_STATE',
      payload: {
        loading: false,
        postingAutocomplete: success.data,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'posting/SET_STATE',
      payload: {
        loading: false,
        postingAutocomplete: [],
      },
    })
    message.error({
      content: success.message,
      key: 'updatable',
    })
  }
}

export function* GET_PACKAGE_CATEGORY() {
    yield put({
      type: 'posting/SET_STATE',
      payload: {
        loading: true,
      },
    })
    const success = yield call(jwts.getPackageCategory)
    if (success.status) {
      yield put({
        type: 'posting/SET_STATE',
        payload: {
          loading: false,
          packageCategory: success?.data,
        },
      })
    }
    if (!success.status) {
      yield put({
        type: 'posting/SET_STATE',
        payload: {
          loading: false,
        },
      })
      notification.error({
        message: 'Error!',
        description: success.message,
      })
    }
  }

  export function* GET_GL_ACCOUNT_CODE() {
    yield put({
      type: 'posting/SET_STATE',
      payload: {
        loading: true,
      },
    })
    const success = yield call(jwts.getGlAccountCode)
    if (success.status) {
      yield put({
        type: 'posting/SET_STATE',
        payload: {
          loading: false,
          glAccountCode: success?.data,
        },
      })
    }
    if (!success.status) {
      yield put({
        type: 'posting/SET_STATE',
        payload: {
          loading: false,
        },
      })
      notification.error({
        message: 'Error!',
        description: success.message,
      })
    }
  }


  export function* GET_TARGET_LIST({payload}) {
    yield put({
      type: 'posting/SET_STATE',
      payload: {
        targetLoading: true,
      },
    })
    const success = yield call(jwts.getTargetList, payload)
    if (success.status) {
      yield put({
        type: 'posting/SET_STATE',
        payload: {
          targetLoading: false,
          targetList: success?.data,
        },
      })
    }
    if (!success.status) {
      yield put({
        type: 'posting/SET_STATE',
        payload: {
          targetLoading: false,
        },
      })
      notification.error({
        message: 'Error!',
        description: success.message,
      })
    }
  }


export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_POSTING_AUTOCOMPLETE, GET_POSTING_AUTOCOMPLETE),
    takeEvery(actions.GET_PACKAGE_CATEGORY, GET_PACKAGE_CATEGORY),
    takeEvery(actions.GET_GL_ACCOUNT_CODE, GET_GL_ACCOUNT_CODE),
    takeEvery(actions.GET_TARGET_LIST, GET_TARGET_LIST),
    takeEvery(actions.REVERSAL, REVERSAL),
    takeEvery(actions.DISPLAY_POSTING_TABLE, DISPLAY_POSTING_TABLE),
    takeEvery(actions.SUBMIT_POSTING_RECORDS, SUBMIT_POSTING_RECORDS),
  ])
}
