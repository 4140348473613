import apiClient from 'services/axios'
import store from 'store'

export async function login(data) {
  return apiClient
    .post('/validate_login', data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function submitToken(data) {
  return apiClient
    .post('/login', data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function register(email, password, name) {
  return apiClient
    .post('/auth/register', {
      email,
      password,
      name,
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function currentAccount() {
  return apiClient
    .get('/auth/account')
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function logout() {
  return apiClient
    .get('/auth/logout')
    .then(() => {
      store.remove('accessToken')
      return true
    })
    .catch(err => console.log(err))
}

export async function getZeroDashboard() {
  return apiClient
    .get('main_dashboard')
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getZeroLoanSummary() {
  return apiClient
    .get('loan/loan_summary')
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getZeroProcurementSummary() {
  return apiClient
    .get('procurement_summary')
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getZeroMembers(data) {
  return apiClient
    .post('members/dashboard', data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getZeroInactiveMembers(data) {
  return apiClient
    .post('members/dashboard2', data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getZeroIncompleteMembers(data) {
  return apiClient
    .post('members/dashboard3', data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getZeroProcureMembers(data) {
  return apiClient
    .post('members/dashboard4', data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function addZeroMember(data) { // 
  return apiClient
    .post('members/add', data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function importZeroMember(data) {
  return apiClient
    .post('members/import', data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function enableDisableZeroMember(data) {
  return apiClient
    .post('members/enable_disable', data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function resendEmailLink(data) {
  console.log(data)
  return apiClient
    .post(`members/resend_emailLink/${data}`, data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}
export async function loanProcureAck(data) {
  return apiClient
    .post(`members/acknowClosure/${data?.id}`, data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}
export async function getZeroMemberDetails(id) {
  return apiClient
    .get(`members/details/${id}`)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function setZeroMemberDetails({ id, data }) {
  return apiClient
    .post(`members/admin_edit/${id}`, data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function setZeroMemberStatus(id) {
  return apiClient
    .post(`members/admin_update_member_status/${id}`)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function setZeroMemberProcurementWaiver(data) {
  return apiClient
    .post(`procurement_waiver`, data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getZeroMemberSavingDetail(id) {
  return apiClient
    .get(`member_savings_details/${id}`)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getZeroMemberGuaranteedLoan(id) {
  return apiClient
    .get(`display_user_loan_guarantee/${id}`)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getZeroMemberSavingTrans(data) {
  return apiClient
    .post(`acc_statement_table/${data?.id}`, data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getZeroMemberTargetDetail(id) {
  return apiClient
    .get(`target_savings_details/${id}`)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getZeroMemberTargetTrans(id) {
  return apiClient
    .get(`member_target_savings_table/${id}`)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getZeroMemberShareDetail(id) {
  return apiClient
    .get(`member_share_capital_details/${id}`)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getZeroMemberShareTrans({ id, data }) {
  return apiClient
    .post(`member_share_capital_table/${id}`, data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getZeroMemberLoanDetail(id) {
  return apiClient
    .get(`member_loan_widgets/${id}`)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getZeroMemberLoanTrans({ id, data }) {
  return apiClient
    .post(`member_loan_table/${id}`, data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getZeroMemberProcurementDetail(id) {
  return apiClient
    .get(`member_procurement_widgets/${id}`)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getZeroMemberPackageDebt(id) {
  return apiClient
    .get(`fetch_packages/${id}`)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getZeroMemberProcurementTrans({ id, data }) {
  return apiClient
    .post(`member_procurement_table/${id}`, data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getZeroMemberWalletTrans({ id, data }) {
  return apiClient
    .post(`member_wallet_table/${id}`, data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getZeroMemberWithdrawalWaiver({ data }) {
  return apiClient
    .post(`display_withdrawal_waiver`, data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function setZeroMemberWithdrawalWaiver(data) {
  return apiClient
    .post(`withdrawal_waiver_button`, data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function setZeroMemberLoanWaiver(data) {
  return apiClient
    .post(`loan_waiver`, data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function moveZeroMemberPackageDebt(data) {
  return apiClient
    .post(`admin_deduct_users_debt`, data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}
export async function uploadProcumentReversal(data) {
  console.log(data)
  return apiClient
    .post('reverse_procurement_upload', data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

// export async function interSavingsTransfer(data) {
//   return apiClient
//     .post(`admin_savings_fund_transfer`, data)
//     .then(response => {
//       if (response) {
//         return response.data
//       }
//       return false
//     })
//     .catch(err => console.log(err))
// }

export async function getZeroAccountSummary(data) {
  return apiClient
    .post('account/dashboard', data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getZeroPendingAccount(data) {
  return apiClient
    .post('account/pending', data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getZeroApprovedAccount(data) {
  return apiClient
    .post('account/approved', data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getZeroCancelledAccount(data) {
  return apiClient
    .post('account/cancelled', data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getZeroAccountDetails(data) {
  return apiClient
    .post(`account/details/${data.id}`, data.data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function approveDeclineZeroAccount(data) {
  return apiClient
    .post('account/approve_cancel', data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getAllLoanRequests(data) {
  return apiClient
    .post('loan/show_all_loans', data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getZeroLoanRequests() {
  return apiClient
    .get('loan/display_loan_admin')
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getZeroProcurementRequests() {
  return apiClient
    .get('display_procurement_admin')
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getZeroActivityRequests(data) {
  return apiClient
    .post('display_activity_log', data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function deleteZeroActivity(data) {
  return apiClient
    .post('delete_activity_log', data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function exportZeroActivity(data) {
  return apiClient
    .post('export_activity_log', data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getZeroLoanRequestDetail(id) {
  return apiClient
    .get(`display_loan_details_admin/${id}`)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getZeroLoanTransactions(data) {
  return apiClient
    .post(`display_loan_transaction`, data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getLoanRepayDetail(data) {
  return apiClient
    .post(`single_loan_details/${data?.colId}`, data)
    // .get(`single_loan_details/${data?.colId}`, data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getAllProcurement(data) {
  return apiClient
    .post('show_all_procurements', data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getZeroProcurementRequestDetail(id) {
  return apiClient
    .get(`display_procurement_details_admin/${id}`)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getZeroProcurementTransactions(data) {
  return apiClient
    .post(`display_procurement_transaction`, data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getProcureRepayDetail(data) {
  return apiClient
    .post(`single_procurement_details/${data?.colId}`, data)
    // .get(`single_procurement_details/${data?.colId}`, data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getTargetTransDetail(id) {
  return apiClient
    // .post(`single_target_details/${id}`)
    .get(`single_target_details/${id}`)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getUserTransactions(data) {
  return apiClient
    .post('display_transactions', data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function checkUploadedExcelData(data) {
  return apiClient
    .post('check_upload_ids', data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function reverseUploadedExcelData(data) {
  console.log(data)
  return apiClient
    .post('upload_reversal', data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}
export async function uploadReverse(data) {
  console.log(data)
  return apiClient
    .post('single_upload_reversal', data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function sendUploadedExcelData(data) {
  return apiClient
    .post('upload_excel_transactions', data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getExcelHistory(data) {
  return (
    apiClient
      .post('uploads_excel_list', data)
      // .post('search_upload_tbl', data)
      .then(response => {
        if (response) {
          return response
        }
        return false
      })
      .catch(err => console.log(err))
  )
}

export async function getUloadTransactions(data) {
  return (
    apiClient
      .post('uploads_tbl', data)
      // .post('search_upload_tbl', data)
      .then(response => {
        if (response) {
          return response
        }
        return false
      })
      .catch(err => console.log(err))
  )
}


export async function getProcureBacklog(data) {
  return (
    apiClient
      .post('get_procurement_backlog', data)
      // .post('search_upload_tbl', data)
      .then(response => {
        if (response) {
          return response
        }
        return false
      })
      .catch(err => console.log(err))
  )
}

export async function submitPostingRecords(data) {
  return apiClient
    .post('submit_posting_records', data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function submitPostingReversal(dat) {
  // console.log(data)
  const data ={id: dat}
  return apiClient
    .post('posting_reversal', data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function displayPostingTable(data) {
  return apiClient
    .post('display_posting_table', data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getUserReportList(data) {
  // transaction_report
  return apiClient
    .post('user_sum_report', data) 
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getUserReportBalance(data) {
  return apiClient
    .post('user_balance_report', data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getUserReportTransactions(data) {
  return apiClient
    .post('user_transaction_report', data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getTransactionCategory() {
  return apiClient
    .get('get_trans_category')
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getWooTransactions(payload) {
  return apiClient
    .post('get_woo_transactions', payload)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getTechendPayLog(payload) {
  return apiClient
    .post('fetch_alat_trans', payload)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getTechendPayBatches(payload) {
  return apiClient
    .post('get_batches', payload)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function processTechendPayLog(payload) {
  return apiClient
    .post('process_alat_transactions', payload)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function alatPayToWallet(payload) {
  return apiClient
    .post('add_failed_alat_wallet', payload)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getBacklogCategory() {
  return apiClient
    .get('transaction_types')
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getBacklogTransaction(data) {
  return apiClient
    .post('all_time_transactions', data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getPackageCategory() {
  return apiClient
    .get('posting_packages_dropdown')
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getGlAccountCode() {
  return apiClient
    .get('gl_bank_accounts')
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getTargetList(id) {
  return apiClient
    .get(`target_packages/${id}`)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function approveDeclineLoan(data) {
  return apiClient
    .post('approve_decline_pending', data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function processLoanRequest(data) {
  return apiClient
    .post('process_loan', data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function closeLoanRequest(data) {
  return apiClient
    // .get(`admin_close_loan/${id}`)
    .post('admin_close_loan', data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getLoanFees(data) {
  return apiClient
    .post('getLoanFees', data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function reverseFee(data) {
  return apiClient
    .post('reverse_fees', data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function approveDeclineProcurement(data) {
  return apiClient
    .post('approve_decline_pending1', data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function processProcurementRequest(data) {
  return apiClient
    .post('process_procurement', data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function addProcurementMarkup(data) {
  return apiClient
    .post('procurement_markup', data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function uploadProcureBackLog(data) {
  return apiClient 
    .post('upload_procurement', data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function balanceResolution(data) {
  return apiClient 
    .post('balance_resolution_script', data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function uploaEditUser(data) {
  return apiClient
    .post('edit_some_users', data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getZeroWithdrawalSummary() {
  return apiClient
    .get('withdrawal_dashboard')
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function approveDeclineZeroWithdrawal(data) {
  return apiClient
    .post('process_withdrawal', data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}
export async function getPendingWithdrawal(data) {
  return apiClient
    .post('display_pending_withdrawal', data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getZeroWithdrawalDetails(id) {
  return apiClient
    .get(`members/display_withdrawal_details_admin/${id}`)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function approveDeclineWithdrawal(data) {
  return apiClient
    .post('members/approve_decline_withdrawal', data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getAllWithdraw(data) {
  return apiClient
    .post('all_withdrawal', data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getZeroPendingWithdrawal(data) {
  return apiClient
    .post('withdrawal_lists_pending', data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getZeroProcessingWithdrawal(data) {
  return apiClient
    .post('withdrawal_lists_processing', data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getZeroCompletedWithdrawal(data) {
  return apiClient
    .post('withdrawal_lists_completed', data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function displaySavingsAccount(data) {
  return apiClient
    .post('display_savings_accounts', data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}
export async function getTargetAutocomplete(data) {
  return apiClient
    .get(`/target_search_other_members/${data}`)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}
export async function getPostingAutocomplete(data) {
  return apiClient
    .get(`/posting_search/${data}`)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}
export async function getTargetHistoryTable({ data, id }) {
  return apiClient
    .post(`target_history_table/${id}`, data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getZeroTargetSummary(id) {
  return apiClient
    .get(`target_savings_summary/${id}`)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function displayOngoingTargetSavingsAccount(data) {
  return apiClient
    .post('ongoing_target_savings', data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function displayCompletedTargetSavingsAccount(data) {
  return apiClient
    .post('completed_target_savings', data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getShareHistoryTable({ data, id }) {
  return apiClient
    .post(`shareholding_history_table/${id}`, data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}
export async function getShareAutocomplete(data) {
  return apiClient
    .get(`/share_search_other_members/${data}`)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}
export async function displayShareCapitalAccount(data) {
  return apiClient
    .post('display_share_capital_savings', data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getShareCapitalSummary(id) {
  return apiClient
    .get(`share_capital_summary/${id}`)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}
export async function getSavingMembersAutocomplete(data) {
  return apiClient
    .get(`/search_other_members/${data}`)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getSavingsWithdrawalDetails({ data, id }) {
  return apiClient
    .post(`savings_withdrawal_details/${id}`, data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getSavingsTransactions({ data, id }) {
  return apiClient
    .post(`single_savings_table/${id}`, data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getZeroSavingSummary(id) {
  return apiClient
    .get(`savings_summary/${id}`)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getAllRoles(data) {
  return apiClient
    .post('display_roles', data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getAllPermissions() {
  return apiClient
    .get('display_permissions')
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getZeroStaffs(data) {
  return apiClient
    .post('show_admin_staff', data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function createZeroRole(data) {
  return apiClient
    .post('settings/add_roles', data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getZeroSingleRole(id) {
  return apiClient
    .get(`fetch_selected_permissions/${id}`)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function updateZeroRole({ id, data }) {
  return apiClient
    .post(`update_roles/${id}`, data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function deleteZeroRole(id) {
  return apiClient
    .post(`delete_roles/${id}`)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function addZeroStaff(data) {
  return apiClient
    .post(`add_admin_staff`, data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getSingleStaffDetails(id) {
  return apiClient
    .get(`display_single_staff/${id}`)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function updateSingleStaff({ id, data }) {
  return apiClient
    .post(`update_staff/${id}`, data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function enableDisableZeroStaff(id) {
  return apiClient
    .get(`disable_enable_staff/${id}`)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}
export async function submitLoanDuration(data) {
  return apiClient
    .post('loan_settings_insert', data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}
export async function displayLoanDuration() {
  return apiClient
    .get('loan_settings_fetch')
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function submitProcurementDuration(data) {
  return apiClient
    .post('procurement_settings_insert', data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}
export async function interSavingsTransfer(data) {
  return apiClient
    .post(`admin_savings_fund_transfer`, data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}
export async function displayProcurementDuration() {
  return apiClient
    .get('procurement_settings_fetch')
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getAllOrganizations(data) {
  return apiClient
    .post('companies_table', data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function createOrganizations(data) {
  return apiClient
    .post('add_companies', data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function deactivateOrganizations(id) {
  return apiClient
    .get(`deactivate_company/${id}`)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getApiKeyList(payload) {
  return apiClient
    .post("get_third_party_details", payload)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}


export async function createApiKey(payload) {
  return apiClient
    .post("create_api_access", payload)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function toggleApiKey(id) {
  return apiClient
    .put(`access_permission/${id}`)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getZeroClosure(data) {
  console.log(data)
  return apiClient
    .post(`members/display_closure`, data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getZeroPendingRefundSummary(data) {
  console.log(data)
  return apiClient
    .post(`display_pending_refund_requests`, data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}
export async function getZeroApprovedRefundSummary(data) {
  return apiClient
    .post(`display_approved_refund_requests`, data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}
export async function getZeroClaimedRefundSummary(data) {
  return apiClient
    .post(`display_claimed_refund_requests`, data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}
export async function getZeroCancelledRefundSummary(data) {
  return apiClient
    .post(`display_canceled_refund_requests`, data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}
export async function getZeroProcessingRefundSummary(data) {
  return apiClient
    .post(`display_processing_refund_requests`, data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}
export async function getZeroDisbursedRefundSummary(data) {
  return apiClient
    .post(`display_disbursed_refund_requests`, data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}
export async function getZeroRefundRequestDetail(id) {
  return apiClient
    .get(`display_refund_details_admin/${id}`)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
} // 

export async function getClosureRequestDetail(id) {
  return apiClient
    .get(`members/display_closure_details_admin/${id}`)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getDoubleExcelDetail(id) {
  return apiClient
    .get(`members/double_excel_details_admin/${id}`)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function approveDeclineRefund(data) {
  return apiClient
    .post('approve_decline_pending_refund', data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function approveDeclineClosure(data) {
  return apiClient
    .post('members/approve_decline_closure', data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function reuploadDeduction(data) {
  console.log(data)
  return apiClient
    .post('reupload_deduction', data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function mulpleReuploadDed(data) {
  console.log(data)
  return apiClient
    .post('multiple_reupload_deduction', data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}
export async function approveDeclineReupload(data) {
  console.log(data)
  return apiClient
    .post('approve_decline_reupload', data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function editMembershipType(data) {
  console.log(data)
  return apiClient
    .post('members/edit_membership_type', data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function processClosure(data) {
  return apiClient
    .post('members/process_closure', data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function editClosureFee(data) {
  return apiClient
    .post('edit_closure_fee', data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function processRefundRequest(data) {
  return apiClient
    .post('process_refund_request', data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function uploadRefundRequest(data) {
  return apiClient
    .post('excel_refund', data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function loadRefund(data) {
  return apiClient
    .post('loan_reversal', data)
    .then(response => {
      if (response) {
        return response?.data
      }
      return false
    })
    .catch(err => console.log(err))
}
