/* eslint-disable camelcase */
import { all, takeEvery, put, call } from 'redux-saga/effects'
import { message, notification } from 'antd'
// import store from 'store'
// import { history } from 'index'
import * as jwt from 'services/jwt'
import actions from './actions'

const jwts = {
  displaySavingsAccount: jwt.displaySavingsAccount,
  getZeroSavingSummary: jwt.getZeroSavingSummary,
  getSavingsTransactions: jwt.getSavingsTransactions,
  getSavingsWithdrawalDetails: jwt.getSavingsWithdrawalDetails,
  getSavingMembersAutocomplete: jwt.getSavingMembersAutocomplete,
}

export function* GET_SAVING_MEMBERS_AUTOCOMPLETE({ payload }) {
  yield put({
    type: 'savings/SET_STATE',
    payload: {
      loading: false,
    },
  })
  const success = yield call(jwts.getSavingMembersAutocomplete, payload)
  if (success.status) {
    yield put({
      type: 'savings/SET_STATE',
      payload: {
        loading: false,
        memberAutocomplete: success.data,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'savings/SET_STATE',
      payload: {
        loading: false,
        memberAutocomplete: [],
      },
    })
    message.error({
      content: success.message,
      key: 'updatable',
    })
  }
}

export function* DISPLAY_REGULAR_SAVINGS_ACCOUNT({ payload }) {
  yield put({
    type: 'savings/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.displaySavingsAccount, payload)
  if (success.status) {
    yield put({
      type: 'savings/SET_STATE',
      payload: {
        loading: false,
        regularSavings: success?.data,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'savings/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success.message,
    })
  }
}

export function* GET_ZERO_SAVINGS_SUMMARY({ payload }) {
  yield put({
    type: 'savings/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getZeroSavingSummary, payload)
  if (success.status) {
    yield put({
      type: 'savings/SET_STATE',
      payload: {
        loading: false,
        summaryData: success.data,
        packageDetails: success?.data?.package_details,
        memberDetails: success?.data?.member_details,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'savings/SET_STATE',
      payload: {
        loading: false,
      },
    })
    message.error({
      message: success.message,
    })
  }
}

export function* GET_SAVINGS_TRANSACTIONS({ payload }) {
  yield put({
    type: 'savings/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getSavingsTransactions, payload)
  if (success.status) {
    yield put({
      type: 'savings/SET_STATE',
      payload: {
        loading: false,
        savingsTransactions: success?.data,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'savings/SET_STATE',
      payload: {
        loading: false,
      },
    })
    message.error({
      message: success.message,
    })
  }
}

export function* GET_SAVINGS_WITHDRAWAL_DETAILS({ payload }) {
  yield put({
    type: 'savings/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getSavingsWithdrawalDetails, payload)
  if (success.status) {
    yield put({
      type: 'savings/SET_STATE',
      payload: {
        loading: false,
        withdrawalDetails: success?.data,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'savings/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success.message,
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.DISPLAY_REGULAR_SAVINGS_ACCOUNT, DISPLAY_REGULAR_SAVINGS_ACCOUNT),
    takeEvery(actions.GET_ZERO_SAVINGS_SUMMARY, GET_ZERO_SAVINGS_SUMMARY),
    takeEvery(actions.GET_SAVINGS_TRANSACTIONS, GET_SAVINGS_TRANSACTIONS),
    takeEvery(actions.GET_SAVING_MEMBERS_AUTOCOMPLETE, GET_SAVING_MEMBERS_AUTOCOMPLETE),
    takeEvery(actions.GET_SAVINGS_WITHDRAWAL_DETAILS, GET_SAVINGS_WITHDRAWAL_DETAILS),
  ])
}
