import store from 'store'
import actions from './actions'

const initialState = {
  id: '',
  name: store.get('adminData')?.name || '',
  role: store.get('adminData')?.role || '',
  email: store.get('adminData')?.email || '',
  avatar: '',
  authorized: process.env.REACT_APP_AUTHENTICATED || false, // false is default value
  userLoginData: store.get('adminData') || {},
  loading: false,
  moda: false,
  permissions: store.get("adminPermissions") || {},
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
