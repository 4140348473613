import { notification } from 'antd'
import { all, takeEvery, put, call } from 'redux-saga/effects'
import * as jwt from 'services/jwt'
import actions from './actions'

const jwts = {
  getZeroActivityRequests: jwt.getZeroActivityRequests,
  deleteZeroActivity: jwt.deleteZeroActivity,
}

export function* GET_ZERO_ACTIVITY_REQUEST({ payload }) {
  yield put({
    type: 'activity/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getZeroActivityRequests, payload)
  if (success.status) {
    yield put({
      type: 'activity/SET_STATE',
      payload: {
        loading: false,
        activityData: success?.data,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'activity/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}
export function* DELETE_ZERO_ACTIVITY({ payload }) {
  yield put({
    type: 'activity/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.deleteZeroActivity, payload)
  if (success.status) {
    yield put({
      type: 'activity/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.success({
      message: success?.message,
    })
    yield put({ type: 'activity/GET_ZERO_ACTIVITY_REQUEST' })
  }
  if (!success.status) {
    yield put({
      type: 'activity/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success?.message,
    })
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_ZERO_ACTIVITY_REQUEST, GET_ZERO_ACTIVITY_REQUEST)])
  yield all([takeEvery(actions.DELETE_ZERO_ACTIVITY, DELETE_ZERO_ACTIVITY)])
}
