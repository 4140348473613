import axios from 'axios'
import store from 'store'
import { message, notification } from 'antd'
import { history } from 'index'

const { href: host } = window.location
export const isLocalEnvironment = host.includes('localhost') || host.includes('surge')
const apiClient = axios.create({
  //  baseURL: 'https://zimcapi.techend.com.ng/api/admin/',
  baseURL:
    host.includes('localhost') || host.includes('surge')
      // ? 'http://137.184.148.224/finnacrest_test/public/api/admin/'
      // ? 'https://zero-api.jovial-shirley.155-254-23-95.plesk.page/zero-api/public/api/admin/'
      // ? 'https://ziapi.techend.com.ng/api/admin/'
      ? 'https://api.zimcserver.com/zero-api/public/api/admin'
      : 'https://ziapi.techend.com.ng/api/admin/'
      // : 'https://zimcapi.techend.com.ng/api/admin/',

  // timeout: 1000,
  // headers: { 'X-Custom-Header': 'foobar' }
})

apiClient.interceptors.request.use(request => {
  const accessToken = store.get('accessToken')
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`
    request.headers.AccessToken = accessToken
  }
  return request
})

apiClient.interceptors.response.use(undefined, error => {
  // Errors handling
  const { response } = error
  const { data } = response || { data: '' }
  const error401 = JSON.stringify(error).includes('401')
  if (error401) {
    history.push('/auth/login')
    store.clearAll()
    message.error({
      content: 'Token has expired, please re-login to continue.',
      duration: 4,
      key: 'updatable',
    })
  } else {
    notification.error({
      message: 'Error!',
      description: String(error),
    })
  }
  if (data) {
    notification.warning({
      message: data,
    })
  }
})

export const formatCurrency1 = num => {
  if (num !== undefined) {
    return parseFloat(Number(num).toFixed(2))
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }
  return 0
}

export const formatCurrency = (value) =>
  new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
  }).format(value || 0);

export default apiClient
