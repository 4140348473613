const actions = {
    SET_STATE: 'posting/SET_STATE',
    GET_POSTING_AUTOCOMPLETE: 'posting/GET_POSTING_AUTOCOMPLETE',
    GET_PACKAGE_CATEGORY: 'posting/GET_PACKAGE_CATEGORY',
    GET_GL_ACCOUNT_CODE: 'posting/GET_GL_ACCOUNT_CODE',
    REVERSAL: 'posting/REVERSAL',
    GET_TARGET_LIST: 'posting/GET_TARGET_LIST',
    DISPLAY_POSTING_TABLE: 'posting/DISPLAY_POSTING_TABLE',
    SUBMIT_POSTING_RECORDS: 'posting/SUBMIT_POSTING_RECORDS',
  }

  export default actions
