import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
// import store from 'store'
// import { history } from 'index'
import * as jwt from 'services/jwt'
import actions from './actions'

const jwts = {
  getWooTransactions: jwt.getWooTransactions,
}

export function* GET_TRANSACTION_LOG({ payload }) {
  yield put({
    type: 'wootransactions/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getWooTransactions, payload)
  if (success.status) {
    yield put({
      type: 'wootransactions/SET_STATE',
      payload: {
        loading: false,
        transactions: success?.data,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'wootransactions/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success.message,
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_TRANSACTION_LOG, GET_TRANSACTION_LOG),
  ])
}
