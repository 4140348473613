import actions from './actions'

const initialState = {
  loading: false,
  data: {},
  tabKey: '0',
  withdrawalsData: [],
  pendingWithdrawal: [],
  withdrawalDetail: {},
  withdrawalAdmin:[],
  allWithdrawal: [],
  pendingWithdrawalData: [],
  processingWithdrawal: [],
  completedWithdrawal: [],
  withdrawalDetailStatus: "",
  // detailTableList: [],
  memberList: [],
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
