import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
// import store from 'store'
// import { history } from 'index'
import * as jwt from 'services/jwt'
import actions from './actions'

const jwts = {
  getTechendPayLog: jwt.getTechendPayLog,
  getTechendPayBatches: jwt.getTechendPayBatches,
  processTechendPayLog: jwt.processTechendPayLog,
  alatPayToWallet: jwt.alatPayToWallet,
}

export function* GET_TRANSACTION_LOG({ payload }) {
  yield put({
    type: 'techendpay/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getTechendPayLog, payload)
  if (success.status) {
    yield put({
      type: 'techendpay/SET_STATE', // summary
      payload: {
        loading: false,
        transactions: success?.data,
        transSummary: success?.summary,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'techendpay/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success.message,
    })
  }
}

export function* GET_TRANSACTION_BATCHES({ payload }) {
  yield put({
    type: 'techendpay/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.getTechendPayBatches, payload)
  if (success.status) {
    yield put({
      type: 'techendpay/SET_STATE', 
      payload: {
        loading: false,
        transBatches: success?.data,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'techendpay/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success.message,
    })
  }
}

export function* PROCESS_TRANSACTIONS({ payload }) {
  yield put({
    type: 'techendpay/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.processTechendPayLog, payload)
  if (success.status) {
    yield put({
      type: 'techendpay/SET_STATE',
      payload: {
        loading: false,
      },
    })
    yield put({type: 'techendpay/GET_TRANSACTION_LOG'})
    yield put({type: 'techendpay/GET_TRANSACTION_BATCHES'})
    notification.success({
      message: "Success!",
      description: success?.message || "transactions processed successfully."
    })
  }
  if (!success.status) {
    yield put({
      type: 'techendpay/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error!',
      description: success.message,
    })
  }
}

export function* ALAT_PAY_TO_WALLET({ payload }) {
  yield put({
    type: 'techendpay/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwts.alatPayToWallet, payload)
  if (success.status) {
    notification.success({
      message: 'Success',
      description: success?.message,
    })
    yield put({
      type: 'techendpay/SET_STATE',
      payload: {
        loading: false,
      },
    })

    yield put({type: 'techendpay/GET_TRANSACTION_LOG'})
    yield put({type: 'techendpay/GET_TRANSACTION_BATCHES'})
  }
  if (!success.status) {
    yield put({
      type: 'techendpay/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Error',
      description: success?.message,
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_TRANSACTION_LOG, GET_TRANSACTION_LOG),
    takeEvery(actions.PROCESS_TRANSACTIONS, PROCESS_TRANSACTIONS),
    takeEvery(actions.GET_TRANSACTION_BATCHES, GET_TRANSACTION_BATCHES),
    takeEvery(actions.ALAT_PAY_TO_WALLET, ALAT_PAY_TO_WALLET),
  ])
}
